.badge{
  background: lightgray;
  padding: 4px 10px 5px;
  border-radius: 18px;
  color: black;
  display: inline-flex;
  font-weight: 500;
  font-family: var(--primary-font);

  &.role{
    color: #1e9484;
    background: #d5f6f2;
  }
}

.headerButtonContainer{
  display: flex;
  gap: 10px;
}