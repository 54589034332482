body {
  background-color: #fbfbfb !important;
}

.icons {
  display: grid;
  grid-template-columns: repeat(auto-fill, 20px);
  gap: 7px;
  justify-content: center;

  i {
    font-size: 14px !important;
    margin-right: 0px !important;
    cursor: pointer;
    text-align: center;

    &.fa-check-circle {
      color: #2ed573;
    }

    &.fa-times-circle {
      color: #ff4757;
    }

    &.fa-redo {
      color: #e67e22;
    }
  }
}

.name-link {
  color: #2f3542;
  font-weight: 600;

  &:hover {
    color: #e81820;
  }
}

.filter-container {
  display: grid;
  grid-template-columns: min-content min-content;
  gap: 10px;

  .filter-btn {
    background-color: #e81a1f;
    border: none;
    color: white;
    border-radius: 4px;
    height: 33px;
    width: 30px;

    i {
      font-size: 14px !important;
    }
  }
}

.users-heading {
  display: grid;
  grid-template-columns: min-content min-content;
  gap: 10px;
  justify-content: space-between;

  .btn {
    min-width: 100px;
  }

  .filter-input {
    height: 33px;
    border: 1px solid #dbdbdb;
    padding: 0px 10px;
    border-radius: 4px;
    outline: none;
    width: 200px;
  }
}

.emails-templates-box {
  font-size: 10px;
  cursor: pointer;

  .pending_emails {
    background-color: #f1b44c;
    width: 13px;
    height: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    font-weight: 900;
    color: white;
  }
}

.users-page {
  .user-container {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) 3fr;

    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }

    .table-container {
      display: grid;
    }

    h2 {
      color: var(--main-dark-color);
    }

    .limit-exceeded {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      top: -2px;
      position: relative;
      left: 5px;
      color: #d52d1b;
    }

    .user-box {
      border-right: 1px solid #ddd;
      margin-bottom: 20px;
      padding-right: 10px;

      h2 {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
          Helvetica Neue, Ubuntu, sans-serif;
        font-weight: 600;
        margin-bottom: 0px;
        font-size: 30px;
        line-height: normal;
      }

      &__email {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
          Helvetica Neue, Ubuntu, sans-serif;
        font-weight: 300;
        font-size: 14px;

        &.user-id {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 10px;
          color: #a4b0be;
        }
      }

      &__action-box {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 5px 0px;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
          Helvetica Neue, Ubuntu, sans-serif;
        font-weight: 400;
        font-size: 14px;

        .status-badge {
          background-color: #ced6e0;
          padding: 2px 10px;
          border-radius: 25px;
          font-weight: 500;
          align-items: center;
          display: inline-block;
          font-size: 12px;
          margin-left: 4px;
        }
      }

      &__heading {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        align-items: center;

        h3 {
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
            Helvetica Neue, Ubuntu, sans-serif;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0px;
          line-height: normal;
        }

      }
    }
    .user-other-data {
      padding: 0px 15px;
      height: 100vh;
      overflow: auto;

      @media screen and (max-width: 900px) {
        padding: 0px;
      }

      h3 {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
          Helvetica Neue, Ubuntu, sans-serif;
        font-weight: 600;
        margin-bottom: 0px;
        font-size: 18px;
        margin-bottom: 5px;
        color: #1a1f36 !important;

        .badge {
          font-size: 14px;
          font-weight: 600;
          margin-left: 10px;
        }
      }

      .limit-exceeded {
        background-color: #ced6e0;
        padding: 4px 10px;
        border-radius: 25px;
        font-weight: 500;
        margin-left: 0px;
      }

      .badge {
        background-color: #ced6e0;
        padding: 4px 10px;
        border-radius: 25px;
        font-weight: 500;
        margin-left: 0px;

        &.completed {
          color: #27ae60;
          background: #2ed5734a;
        }

        &.processing {
          background-color: #0652dd57;
          padding: 4px 10px;
          border-radius: 25px;
          font-weight: 500;
          color: #0652dd;
        }

        &.request {
          background-color: #57606f57;
          color: #57606f;
        }

        &.pending {
          background-color: #ffc31233;
          color: #f79f1f;
        }

        &.running {
          color: #1e90ff;
          background: hsl(210, 100%, 76%);
        }
      }
    }
  }
}

.br-4 {
  border-radius: 4px;
}

.mt-30 {
  margin-top: 30px;
}
