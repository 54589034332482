.linkedinPage {
  .noMargin {
    margin-top: 0px;
  }

  .reuse {
    margin-top: 20px;
    background: none;
    height: 28px;
    border-radius: 5px;
    padding: 0px 13px;
    transition: 0.75s background;
    // border: 1px solid #d6d7db;
    font-family: var(--primary-font);
    font-weight: 500;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    color: #575757;

    i {
      font-size: 12px;
    }

    // &:hover {
    //   background: #000766;
    // }
  }

  h2 {
    font-family: var(--primary-font);
    font-size: 20px;
    font-weight: 700;
    color: var(--main-dark-color);
    line-height: initial;
    margin-top: 15px;
    margin-bottom: 10px;

    i {
      color: var(--third-color);
      margin-left: 5px;
    }
    
  }

  .dates{
    display: flex;
    gap: 25px;
  }

  .attempts{
    margin-bottom: 5px;
    b {
      color: var(--dark);
      margin-right: 0px;
    }
  }

  .fields{
    margin-bottom: 5px;
    display: inline-block;

    b {
      color: var(--dark);
      margin-right: 0px;
    }

    i {
      margin-left: 5px;
      color: #a6a6a6;
    }
  }

  .status{
    margin-bottom: 5px;
    font-family: var(--primary-font);

    b {
      color: var(--dark);
      margin-right: 0px;
    }
  }

  h3 {
    font-size: 14px;
    line-height: initial;
    font-weight: 500;
    font-family: var(--primary-font);
    margin-bottom: 5px;

    b {
      color: var(--dark);
      margin-right: 5px;
    }
  }

  .tableContainer {
    margin-top: 20px;
  }

  .loaderContainer {
    margin-top: 20px;
  }

  .majorStatus {
    display: flex;
    gap: 20px;
    margin-top: 20px;

    .box {
      padding: 13px 30px;
      background: #ffffff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -2px rgba(0, 0, 0, 0.1);

      .number {
        font-size: 20px;
        font-weight: 700;
        font-family: var(--primary-font);
        color: black;
      }

      .name {
        font-family: var(--primary-font);
        color: gray;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .searchBox {
    font-weight: 500;
    font-family: var(--primary-font);
    color: var(--third-color);
    line-height: normal;
    margin-top: 20px;

    label {
      font-weight: 600;
      font-family: var(--primary-font);
      margin-bottom: 5px;
      font-size: 13px;
      color: var(--main-dark-color);
      margin-right: 5px;
    }

    a {
      color: #4a54fa;
      background-color: hsl(237deg, 94%, 90%);
      padding: 3px 10px 4px;
      border-radius: 15px;
      font-weight: 600;
      font-size: 12px;

      i {
        font-size: 11px;
        margin-right: 3px;
      }
    }

    .campaign {
      color: #ff4d4e;
      background-color: hsl(0deg, 100%, 93%);
      padding: 3px 10px 4px;
      border-radius: 15px;
      font-weight: 600;
      font-size: 12px;
      margin-left: 10px;
      cursor: pointer;

      i {
        font-size: 11px;
        margin-right: 3px;
      }
    }

    .download {
      color: #24b761;
      background-color: #d4f7e3;
      padding: 3px 10px 4px;
      border-radius: 15px;
      font-weight: 600;
      font-size: 12px;
      margin-left: 10px;
      cursor: pointer;

      i {
        font-size: 11px;
        margin-right: 3px;
      }
    }
  }
}
