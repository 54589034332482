.emptyFollowUp{
  padding: 100px 50px;
  background: hsl(214deg, 32%, 95%);
  font-weight: 500;
  font-family: var(--primary-font);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  color: #0f172a;
  font-size: 1rem;
  border: 1px solid #ebebeb;

  span {
    font-family: var(--primary-font);
    color: #4953fa;
    font-weight: 700;
    font-size: 1rem;
    cursor: pointer;
    margin-left: 5px;
  }
}