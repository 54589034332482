.vertical-menu {
  width: 200px;
  background: #fff;
  border-right: 1px solid #ededed;
  top: 35px;
}

a {
  &[aria-current="page"] {
    color: #2f3642 !important;
    font-weight: 500;
  }

  span {
    font-family: var(--primary-font);
    font-size: 14px;
    font-weight: 500;
  }
}

#sidebar-menu ul li a i {
  font-size: 14px !important;
  color: hsl(213deg, 23%, 70%) !important;
  padding-bottom: 0px;
}

.fas {
  font-size: 16px;
}

.badge {
  background-color: #ffc41433;
  color: #f79f1f;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 4px;
}

.badge-processing {
  background-color: #0652dd57;
  color: #0652dd;
}

.badge-request {
  background-color: #57606f57;
  color: #57606f;
}
