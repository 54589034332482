.limitsExceeded {
  background: hsl(0deg, 100%, 88%);
  margin-top: 5px;
  padding: 7px 10px;
  color: #d52d1b;
  font-weight: 500;
  font-family: var(--primary-font);
  font-size: 13px;
  border-radius: 4px;
}

.bar {
  width: 100%;
  height: 8px;
  background: hsl(0deg, 0%, 92%);
  margin-top: 10px;
  border-radius: 4px;
  margin-bottom: 3px;

  .filled {
    height: 8px;
    background: linear-gradient(45deg, #d52d1a, #ef513f);
    border-radius: 4px;
    transition: 1s width;
  }
}

.labels {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  font-family: var(--primary-font);
  font-weight: 500;
}
