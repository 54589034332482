.box {
  input {
    width: 100%;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile-1 + label {
    color: #fff !important;
    background-color: #ff4d4f;
    font-family: var(--primary-font);
    width: 100%;
  }
  
  .inputfile-1:focus + label,
  .inputfile-1.has-focus + label,
  .inputfile-1 + label:hover {
    color: #fff !important;
    background-color: #ff4d4f;
  }
  
  .inputfile + label {
    max-width: 100%;
    font-size: 0.80rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 8px 20px;
  }
  
  .inputfile-1 + label {
    color: #fff !important;
    background-color: #ff4d4f;
    border-radius: 7px;
  }
  
  .inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
  }
}