.limitsExceeded {
  background: hsl(0deg, 100%, 88%);
  margin-top: 5px;
  padding: 7px 10px;
  color: #d52d1b;
  font-weight: 500;
  font-family: var(--primary-font);
  font-size: 13px;
  border-radius: 4px;

  a {
    color: #5f26cd;
  }
}

.limits75 {
  background: #dfe4ea;
  margin-top: 5px;
  padding: 7px 10px;
  color: #57606f;
  font-weight: 500;
  font-family: var(--primary-font);
  font-size: 13px;
  border-radius: 4px;

  a {
    color: #5f26cd;
  }
}

.limits90 {
  background: #f6e58d;
  margin-top: 5px;
  padding: 7px 10px;
  color: hsl(39deg, 100%, 43%);
  font-weight: 500;
  font-family: var(--primary-font);
  font-size: 13px;
  border-radius: 4px;

  a {
    color: #5f26cd;
  }
}

.limits100 {
  background: hsl(0deg, 100%, 88%);
  margin-top: 5px;
  padding: 7px 10px;
  color: #d52d1b;
  font-weight: 500;
  font-family: var(--primary-font);
  font-size: 13px;
  border-radius: 4px;

  a {
    color: #5f26cd;
  }
}
