p {
  margin-bottom: 5px !important;
  a.text-primary {
    color: #e81a1f !important;

    &:hover {
      color: #e81a1f !important;
      text-decoration: underline;
    }
  }
}

.alert {
  margin-bottom: 0px !important;
}

.bg-soft-primary {
  background-color: rgb(232, 26, 31) !important;

  .text-primary {
    color: white !important;
    margin-bottom: 0px;
  }

  p {
    margin-bottom: 0px !important;
  }
}
