.modal {
  display: none;
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  grid-template-rows: minmax(40px, 1fr) auto minmax(40px, 2fr);
  grid-template-columns: 40px 8fr 40px;

  &.open {
    display: grid;
  }

  .boxContainer {
    grid-row: 2;
    grid-column: 2;

    .box {
      width: 100%;
      max-width: 450px;
      background: white;
      margin: 0 auto;
      display: flex;
      max-height: calc(100vh - 80px);
      flex-direction: column;
      --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      border-radius: 6px;

      .header {
        padding: 12px 20px;
        font-family: var(--primary-font);
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid gainsboro;
      }

      .body {
        padding: 12px 20px;
        overflow: hidden;
        overflow-y: auto;
      }

      .footer {
        padding: 12px 20px;
        border-top: 1px solid gainsboro;
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        button {
          background: none;
          height: 28px;
          border-radius: 5px;
          padding: 0px 13px;
          transition: 0.75s background;
          // border: 1px solid #d6d7db;
          font-family: var(--primary-font);
          font-weight: 500;
          background-color: rgb(255, 255, 255);
          box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
            rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
          display: flex;
          align-items: center;
        }

        .primary {
          background: rgb(255, 77, 79);
          color: white;
          box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
            rgb(255, 77, 79) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;

          &:hover {
            background: #ff3336;
          }
        }
      }
    }
  }
}
