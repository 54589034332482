.icons {
  i {
    font-size: 18px;
    margin-right: 10px;
    cursor: pointer;

    &.fa-check-circle {
      color: #2ed573;
    }

    &.fa-times-circle {
      color: #ff4757;
    }
  }
}

.btn-primary {
  color: white !important;
}

.approve {
  background-color: #10ac84;
  border-color: #10ac84;

  &:hover {
    background-color: #1dd1a1;
    border-color: #1dd1a1;
  }
}

.status-approved {
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto;
  gap: 5px;
}

.yellow {
  color: #f1c40f;
}

.green {
  color: #2ed573;
}
