.nav {
  background-color: #d52d1a;
  position: sticky;
  top: 0px;
  z-index: 100000;

  &.light {
    background-color: #f6f8fa;
    border-bottom: 1px solid #ebeef1;

    .nav-logo {
      cursor: pointer;

      img {
        filter: none;
      }
    }

    .item {
      i {
        color: #57606f;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .item {
      a {
        display: none;
      }
    }
  }

  .get-leads {
    a {
      position: relative;
      background: var(--secondary-color);
      padding: 7px 10px;
      border-radius: 4px;
      font-family: var(--primary-font);
      font-weight: 600;
      font-size: 12px;
      top: -1px;

      &[aria-current="page"] {
        color: white !important;
      }
    }
  }

  .chrome-extension {
    position: relative;
    background: var(--third-color);
    padding: 7px 15px;
    border-radius: 25px;
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 12px;
    top: -1px;

    i {
      color: #42d1a1 !important;
      top: 1px;
      position: relative;
      margin-right: 4px;
    }

    &[aria-current="page"] {
      color: white !important;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    padding: 3px 0px;
  }

  .container-fluid {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
  }

  .nav-logo {
    display: flex;
    align-items: center;

    img {
      margin: 0px;
      filter: brightness(0) invert(1);
      height: 29px;
    }
  }

  .nav-items {
    display: flex;
    justify-content: flex-end;
    height: 35px;
    align-items: center;

    .item {
      margin-left: 20px;
      color: white;

      &.linkedin {
        background-color: #0a66c2;
        padding: 2px 10px;
        border-radius: 25px;
      }

      &.dropdown {
        position: relative;
        display: flex;
        height: 22px;
        align-items: center;

        .dropdown-list {
          display: none;

          &:hover {
            display: block;
          }
        }

        &:hover {
          .dropdown-list {
            display: block;
            position: absolute;
            width: 170px;
            right: 0px;
            top: 20px;
            background-color: white;
            border-radius: 3px;

            .child {
              display: flex;
              justify-content: center;
              height: 25px;
              align-items: center;

              * {
                color: black;
              }
            }
          }
        }
      }

      a {
        color: white;
      }

      i {
        cursor: pointer;
        font-size: 14px !important;
      }

      @media screen and (max-width: 700px) {
        font-size: 10px;
        margin-left: 13px;
      }

      &:first-child {
        margin: 0px;
      }

      .validation-credits {
        background-color: hsl(206deg, 96%, 41%);
        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 10px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 12px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
          "Segoe UI Symbol";
        transition: 0.5s background-color;

        &:hover {
          cursor: pointer;
          background-color: hsl(206deg, 96%, 41% / 70%);

          &>div {
            background-color: rgba(4, 118, 205,0.02);
          }
        }

        &>div {
          padding: 6px 10px;
          background-color: hsl(206deg, 96%, 41%);
          border-left: 1px solid hsl(206deg, 96%, 31%);
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          transition: 0.5s background-color;
        }
      }

      .credits {
        background-color: hsl(359deg, 100%, 65%);
        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 10px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 12px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
          "Segoe UI Symbol";
        transition: 0.5s background-color;

        &:hover {
          cursor: pointer;
          background-color: #ff787b;

          &>div {
            background-color: #ff787b;
          }
        }

        &>div {
          padding: 6px 10px;
          background-color: hsl(359deg, 100%, 65%);
          border-left: 1px solid hsl(359deg, 100%, 55%);
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          transition: 0.5s background-color;
        }
      }

      button {
        background-color: #b0281a;
        border: none;
        border-radius: 20px;
        padding: 2px 10px;
        color: white;
        font-size: 12px;
        cursor: pointer;

        @media screen and (max-width: 700px) {
          font-size: 10px;
        }

        &.information {
          i {
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

.rs-popover {
  // padding-right: 10px;
  z-index: 100000 !important;

  &.information-popover {
    .rs-popover-content {
      margin: 0px;
      border: none;
      padding: 0px;

      .option {
        width: auto;
        padding-right: 10px;
      }
    }
  }
}

.rs-popover-content {
  border-top: 1px solid #dfe4ea;
  margin: 0px;
  padding-top: 8px;
  margin-top: 8px;

  .option {
    font-size: 12px;
    letter-spacing: 0.3px;
    font-weight: 400;
    color: #747d8c;
    width: 100%;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";

    &:hover {
      color: var(--main-dark-color);
    }

    a {
      color: #747d8c;

      &:hover {
        color: var(--main-dark-color);
      }
    }
  }

  .option-beta {
    font-size: 10px;
    letter-spacing: 0.3px;
    font-weight: 400;
    color: #747d8c;
    width: 160px;
    cursor: default;
    margin-top: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
  }
}