.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .premium {
    color: #3742fa;
    font-size: 11px;
    font-family: var(--primary-font);
    font-weight: 500;
    background: hsl(237deg, 95%, 95%);
    border-radius: 30px;
    padding: 1px 6px 2px;
  }

  label {
    font-weight: 600;
    font-family: var(--primary-font);
    margin-bottom: 5px;
    font-size: 13px;
    color: var(--main-dark-color);

    span {
      color: #a4a4a4;
      font-size: 0.7rem;
      font-weight: 500;
    }
  }

  // input {
  //   height: 28px;
  //   padding: 0px 7px;
  //   border: 1px solid #e0e1e4;
  //   border-radius: 4px;
  //   display: flex;
  //   align-items: center;
  //   font-size: 13px;
  //   font-weight: 500;
  //   font-family: var(--primary-font);

  //   &:focus-visible {
  //     outline: solid #ff4d4f2b;
  //   }
  // }

  .dateTime {
    display: flex;
    gap: 10px;
  }

  .captured {
    display: flex;
    gap: 8px;
    align-items: center;
    font-family: var(--primary-font);
    font-size: 13px;
  }
}