.status{
  &.default {
    background-color: #e6e6e6;
    color: #666666;
  }

  &.completed {
    color: #27ae60;
    background: #d5f6e3;
  }

  &.error {
    color: #e84118;
    background: #fad5cc;
  }

  display: initial;
  font-weight: 600;
  font-family: var(--primary-font);
  font-size: 12px;
  padding: 3px 7px;
  border-radius: 4px;
}