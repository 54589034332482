.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .dateTime {
    display: flex;
    gap: 10px;
  }

  .daysGroup {
    display: flex;
    flex-direction: column;

    label {
      font-weight: 400;
    }
  }

  .errorMessage {
    color: #dc2626;
  }

  .captured {
    display: flex;
    gap: 8px;
    align-items: center;
    font-family: var(--primary-font);
    font-size: 13px;
  }

  .sublabel {
    font-size: 0.8rem;
    color: gray;
    font-family: var(--primary-font);
    line-height: normal;
  }

  .premium {
    color: #3742fa;
    font-size: 11px;
    font-family: var(--primary-font);
    font-weight: 500;
    background: hsl(237deg, 95%, 95%);
    border-radius: 30px;
    padding: 1px 6px 2px;
  }

  .social {
    color: #ff4d4f;
    font-size: 11px;
    font-family: var(--primary-font);
    font-weight: 500;
    background: hsl(359, 100%, 95%);
    border-radius: 30px;
    padding: 1px 6px 2px;
  }

  label {
    font-weight: 600;
    font-family: var(--primary-font);
    margin-bottom: 5px;
    font-size: 13px;
    color: var(--main-dark-color);

    span {
      font-size: 0.6rem;
      color: hsl(0deg, 0%, 73%);
      font-family: var(--primary-font);
    }
  }
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 10px;

  span {
    background: #4953fa;
    color: white;
    padding: 4px 9px 5px;
    font-family: var(--primary-font);
    border-radius: 16px;
    font-weight: 500;
    font-size: 0.8rem;
  }
}

.twoCols {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .formGroup {
    width: 100%;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.headerSection {
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 600;
  color: var(--main-dark-color);
  margin-bottom: 10px;
  margin-top: 10px;
}

.buyPremium>i {
  color: #e81821 !important;
  // margin-left: 5px;
  // margin-top: 1px;
  font-size: 16px;
  position: relative;
  top: 1px;
}

.buyPremium {
  border-radius: 50%;
  height: 16px;
  top: 2px;


  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  // margin: 10px;
  // height: 20px;
  // width: 20px;
  transform: scale(1);
  animation: pulse-red 2s infinite;
  margin-top: 1px;
  margin-left: 5px;
  position: relative;

  &>div {
    width: 200px;
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}