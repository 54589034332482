@import "../../shared/shared.scss";

.addSearch {

  .step1,
  .step2,
  .step3 {
    h2 {
      font-size: 18px;
      line-height: normal;
      margin-top: 25px;
      font-family: var(--primary-font);
      margin-bottom: 2px;
    }

    h3 {
      line-height: normal;
      font-size: 13px;
      margin-bottom: 20px;
      font-family: var(--primary-font);
      font-weight: 500;
      color: gray;
    }

    .filtersContainer {
      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .autocomplete {
          input {
            border: none !important;
          }
        }

        .z100000 {
          z-index: 100000;
        }

        .fieldGroup {
          display: flex;
          flex-direction: column;

          label {
            font-weight: 600;
            font-family: var(--primary-font);
          }

          .control {
            border-color: #1e90ff;
          }

          input {
            height: 24px;
            padding: 0px 10px;
            font-family: var(--primary-font);
            font-size: 15px;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
          }
        }
      }
    }

    .buttonContainer {
      display: flex;
      gap: 10px;
      margin-top: 20px;
      margin-bottom: 20px;

      button {
        &:disabled {
          background: #a4b0be;
          cursor: not-allowed;
        }
      }
    }
  }

  .step2,
  .step3 {
    .filtersContainer {
      .grid {
        display: flex;
        gap: 20px;
      }
    }

    .resultsTop {
      margin-top: 50px;
    }

    .fieldGroup {
      display: flex;

      .label {
        margin-bottom: 5px;
      }

      .value {
        background: rgb(73, 83, 250);
        display: flex;
        width: auto;
        color: white;
        padding: 0px 12px 2px;
        border-radius: 10px;
        font-weight: 600;
        font-family: var(--primary-font);
      }
    }
  }
}

.error {
  font-family: var(--primary-font);
  color: red;
}

.react-select__control {
  height: 45px;
}

.inputWant {
  height: 28px;
  padding: 0px 7px;
  border: 1px solid #e0e1e4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  font-family: var(--primary-font);
  width: 200px !important;
}

.searchContainer {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 50px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .filterContainer {
    background: white;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border: 1px solid gainsboro;
    border-radius: 5px;

    .header {
      padding: 12px 10px;
      font-family: var(--primary-font);
      font-size: 14px;
      font-weight: 600;
      border-bottom: 1px solid gainsboro;

      h3 {
        font-size: 10px;
        margin: 0px;
        line-height: normal;
        font-weight: 300;
        font-family: var(--primary-font);
      }
    }

    .footer {
      padding: 12px 10px;
      border-top: 1px solid #dcdcdc;
      display: flex;
      justify-content: flex-end;
      grid-gap: 10px;
      gap: 10px;
    }

    .filters {
      padding: 10px 0px;

      .filterBox {
        padding: 10px;
        padding-top: 0px;
        padding-bottom: 8px;

        label {
          margin-bottom: 4px;
          font-size: 12px;
          font-weight: 600;
          font-family: var(--primary-font);

          &.checkboxField {
            display: flex;
            align-items: center;
            gap: 6px;
            font-weight: 600;
            margin-top: 6px;
          }
        }

        .checkbox {
          margin-top: 6px;

          label {
            display: flex;
            align-items: center;
            gap: 5px;
          }

          .premium {
            padding: 3px 10px;
            background-color: #3742fa;
            border-radius: 5px;
            font-family: var(--primary-font);
            font-weight: 600;
            color: white;
          }
        }

        .input {
          border: 1px solid #e0e1e4;
          display: block;
          border-radius: 5px;
          height: 38px;
          width: 100%;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 13px;
          font-family: var(--primary-font);
          font-weight: 500;

          &::placeholder {
            color: #c3c3c3;
          }
        }

        .warning {
          background: hsl(48deg, 89%, 94%);
          color: hsl(48deg, 89%, 43%);
          padding: 10px;
          border-radius: 4px;
          font-family: var(--primary-font);
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
  }

  .resultsContainer {
    h2 {
      font-size: 14px;
      margin: 0px;
      line-height: normal;
      font-family: var(--primary-font);
      font-weight: 600;
      color: black;
      margin-bottom: 10px;
    }

    .results,
    .startSearch {
      background: white;
      --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      border: 1px solid gainsboro;
      border-radius: 5px;

      .header {
        padding: 12px 10px;
        font-family: var(--primary-font);
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid gainsboro;
        display: flex;
        justify-content: space-between;

        .numbers {
          font-size: 12px;
          font-weight: 400;
          color: gray;
          padding-top: 2px;
        }
      }

      .result {
        background: white;
        border-bottom: 1px solid gainsboro;
        padding: 15px 10px;

        .image {
          display: flex;
          width: 50px;
          height: 50px;
          align-items: flex-start;
          justify-content: initial;

          img {
            margin: 0;
            background: #a4b0be;
            border-radius: 50%;
            object-fit: contain;
            object-position: bottom;
            width: 100%;
          }
        }

        .name {
          font-family: var(--primary-font);
          font-weight: 700;
          font-size: 14px;
          color: #2f3542;
          line-height: normal;
          text-transform: capitalize;
          margin-bottom: 2px;

          a {
            width: 18px;
            height: 18px;
            background: rgb(0, 119, 181);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 9px;
            border-radius: 5px;
            margin-left: 5px;
            color: white;
            position: absolute;
          }
        }

        .email {
          display: flex;
          align-items: center;
          color: #747d8c;

          div {
            color: #ced6e0;
            font-size: 13px;
            letter-spacing: 2px;
            margin-left: 5px;
            height: 15px;
            background: hsl(213deg, 23%, 96%);
            padding: 2px 6px 0px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            top: 1px;
            position: relative;
          }

          font-weight: 600;
          font-family: var(--primary-font);
          font-size: 12px;
          margin-bottom: 2px;
        }

        .jobTitle {
          font-weight: 500;
          font-family: var(--primary-font);
          font-size: 11px;
          color: #747d8c;
          margin-bottom: 2px;

          .title {
            font-weight: 600;
            text-transform: capitalize;
          }

          .company {
            font-weight: 600;
            text-transform: uppercase;
            color: #2f3542;
          }
        }

        .location {
          font-size: 12px;
          font-family: var(--primary-font);
          color: gray;
          font-weight: 500;
          margin-top: 10px;
          text-transform: capitalize;
        }

        display: grid;
        grid-template-columns: 60px 1fr;
        grid-gap: 10px;
        gap: 10px;
        align-items: flex-start;
      }

      .footer {
        padding: 12px 10px;
        border-top: 1px solid #dcdcdc;
        display: flex;
        justify-content: flex-end;
        grid-gap: 10px;
        gap: 10px;
      }
    }

    .startSearch {
      .search {
        padding: 10px;

        .textDescription {
          line-height: normal;
          margin-bottom: 5px;
          color: #747d8c;
          font-family: var(--primary-font);
          font-weight: 400;
          font-size: 13px;
        }

        .labelsInput {
          font-size: 14px;
          font-family: var(--primary-font);
          font-weight: 500;
          color: var(--main-dark-color);

          span {
            color: #a4b0be;
            font-size: 11px;
            margin-left: 3px;
          }
        }

        input {
          height: 28px;
          padding: 0px 7px;
          border: 1px solid #e0e1e4;
          border-radius: 4px;
          display: flex;
          align-items: center;
          font-size: 13px;
          font-weight: 500;
          font-family: var(--primary-font);

          &:focus-visible {
            outline: solid #ff4d4f2b;
          }
        }

        .leads {
          h4 {
            b {
              color: black;
            }
          }

          input {
            padding: 10px 10px;
          }
        }

        h4 {
          font-size: 13px;
          font-family: var(--primary-font);
          font-weight: 500;
        }

        .optionContainer {
          align-items: center;
          display: flex;
          gap: 25px;

          .option {
            align-items: center;
            display: flex;

            span {
              background: #d52d1b;
              color: white;
              padding: 2px 10px;
              border-radius: 5px;
              font-weight: 600;
              font-family: var(--primary-font);
              margin-left: 5px;
              font-size: 12px;
            }
          }
        }

        .add_campaign {
          margin-top: 10px;
          margin-bottom: 10px;

          select {
            width: 200px;
          }
        }

        .costCredits {
          margin-top: 20px;
          background: #e0ddf8;
          padding: 0px 20px;
          border-radius: 5px;
          color: #4834d4;
          font-family: var(--primary-font);
          font-weight: 600;
          display: inline-flex;
        }

        .leadsType {
          h4 {
            b {
              cursor: help;
            }
          }
        }

        input {
          width: 100%;
          height: 30px;
          margin-bottom: 10px;
          border: 1px solid #dfdfdf;

          &[type="radio"] {
            width: 15px;
            height: 15px;
            margin: 0px;
            margin-right: 10px;
          }
        }
      }

      .footer {
        button {
          &:disabled {
            background: #d3d4d8;
          }
        }
      }
    }
  }
}