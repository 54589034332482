.enrichmentCard{
  
  margin-bottom: 15px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -2px rgba(0,0,0,0.1);
  cursor: pointer;
  display: flex;

  & > div {
    padding: 15px 15px;

    &:first-child {
      font-size: 25px;
      display: flex;
      align-items: center;
    }
  }

  .iconStatus {
    color: gray;
    background: #dedede;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;

    &.completed {
      color: #27ae60;
      background: #d5f6e3;
    }
  }

  h2 {
    color: black;
    margin-bottom: 5px;
    margin-top: 0px;
  }

  .metadata{
    display: flex;
    gap: 20px;
    font-family: var(--primary-font);
    margin-bottom: 5px;

    b{
      color: var(--dark);
      font-weight: 600;
      margin-right: 3px;
    }
  }
}