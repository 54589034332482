.filterContainer {
  display: flex;
  gap: 10px;

  .inputContainer {
    position: relative;

    input {
      height: 32px;
      padding: 0px 10px;
      border: 1px solid #d4d5d7;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      font-family: var(--primary-font);
      top: -1px;
      position: relative;
      padding-right: 30px;
    }

    .clean {
      position: absolute;
      right: 4px;
      height: 25px;
      border-radius: 3px;
      top: 3px;
      display: flex;
      font-weight: 500;
      background: #d9d9d9;
      align-items: center;
    }
  }

  .filterBtn {
    background: #ff4d4f;
    color: white;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      #ff4d4f 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    position: relative;
    top: 1px;
    height: 28px;
    border-radius: 5px;
    padding: 0px 13px;
    transition: 0.75s background;
    font-family: var(--primary-font);
    font-weight: 500;
  }

  .filterButton {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(64, 68, 82, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
    min-height: 30px;
    padding: 0px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-family: var(--primary-font);
    font-weight: 500;
    letter-spacing: 0.3px;
    cursor: pointer;
    gap: 10px;

    i {
      font-size: 12px;
      position: relative;
      top: 1px;
    }

    &.active {
      i {
        color: #4953fa;
      }
    }
  }
}

.filtersContainer {
  display: flex;
  // padding: 6px 10px;
  align-items: center;
  width: 145px;
  flex-direction: column;

  & > div:first-child {
    width: 100%;
    padding: 5px 10px;
  }

  & > div:last-child {
    width: 100%;
  }

  .optionContainer{
    padding: 5px 10px;
  }

  .option {
    label {
      margin: 0px;
      display: flex;
      align-items: center;
      grid-gap: 10px;
      gap: 10px;
      font-family: var(--primary-font);
      font-weight: 500;
      color: black;
    }
  }

  .choices {
    width: 100%;
    background: #f5f8fa;
    padding: 5px 10px;
    font-size: 12px;
    font-family: var(--primary-font);
    font-weight: 600;
    border-radius: 3px;

    .options {
      display: flex;
      align-items: center;
      grid-gap: 5px;
      gap: 10px;
      margin-bottom: 3px;
      font-family: var(--primary-font);
      font-weight: 600;
      color: #747d8c;
    }
  }
}
