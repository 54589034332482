.linkedin-searchs {
  .container {
    padding: 3px 0px;
  }
  .loadmore-btn{
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #ff4d4f;
    border-left: 1px solid #ff1a1d;
    color: white;
    font-family: var(--primary-font);
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
            rgb(255, 77, 79) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  }
  section {
    margin-top: 0px;

    h2 {
      font-family: var(--primary-font);
      font-size: 20px;
      font-weight: 700;
      color: var(--main-dark-color);
    }

    .campaigns {
      h3 {
        font-family: var(--primary-font);
        font-size: 16px;
        color: #d52d1b;
      }

      &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 10px;

        &-addbox {
          background: #f3f3f3a3;
          width: 100%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100px;
          border-radius: 8px;
          border: 2px dashed #d52d1b;
          cursor: pointer;
          transition: 0.5s background;

          i {
            color: #d52d1b;
            font-size: 25px !important;
          }

          &:hover {
            background: #8e180b;
            border-color: #8e180b;

            i {
              color: white;
            }
          }
        }

        &-box {
          text-align: center;
          padding: 10px;
          background: linear-gradient(45deg, #d52d1a, #f09389);
          width: 100%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100px;
          border-radius: 8px;
          cursor: pointer;
          font-weight: 600;
          position: relative;
          transition: 0.5s background;
          --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

          .name {
            font-family: var(--primary-font);
          }

          .actions {
            display: none;

            p,
            a {
              color: white;
            }
          }

          &:hover {
            .actions {
              display: block;
              position: absolute;
              top: 0px;
              left: 0px;
              background: #b71f0e;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 8px;

              a {
                color: white;
                margin-bottom: 5px;
              }
            }
          }

          i {
            color: #d52d1b;
            font-size: 25px !important;
          }
        }
      }
    }
  }
}
