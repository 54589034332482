.stats {
  .leads {
    background-color: #e81821;
  }

  & > div {
    background-color: #e81821 !important;
  }
}

.status {
  &.RUNNING {
    background-color: #f8a309 !important;
  }
}

.versionBadge{
  background-color: #fde047;
  padding: 1px 7px 2px;
  border-radius: 15px;
  font-family: var(--primary-font);
  font-size: 0.7rem;
  font-weight: 600;
  color: black;
}