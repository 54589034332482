.emailsSent{
  background-color: #ed4a52;
  color: white;
  font-family: var(--primary-font);
  padding: 5px 13px;
  margin-top: 10px;
  margin-right: -13px;
  margin-left: -13px;

  b {
    display: flex;
    line-height: 10px;
    margin-bottom: 5px;
  }
}