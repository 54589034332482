.tools {
  margin: 10px 0px;
  margin: 10px 0px;
  padding: 10px 0px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  gap: 10px;
  overflow: auto;

  .item {
    cursor: pointer;
    white-space: nowrap;
    background: rgb(73, 83, 250);
    display: inline-block;
    color: #ffffff;
    padding: 5px 15px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    font-family: var(--primary-font);
    transition: 0.5s background;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgb(73, 83, 250) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;

    &:hover {
      background: #3842fa;
    }

    img {
      margin: 0px;
      width: 16px;
      display: inline-block;
      filter: brightness(0) invert(1);
      position: relative;
      top: -1px;
      margin-right: 7px;
    }

    i {
      color: white !important;
      margin-right: 7px;
      position: relative;
      top: 1px;
    }
  }
}

.noPaddings {
  padding: 0px !important;
}