.error {
  color: #b30404;
}

.form-horizontal {
  padding-top: 25px;
}

img {
  margin: 0 auto 40px;
  display: block;
}

.btn {
  background-color: #e81a1f;
  border: none;
  font-weight: 600;
  font-size: 16px;

  &:hover,
  &:focus,
  &:active {
    background-color: #bf161a;
  }
}

.btn-primary:disabled {
  background-color: #e81a1f;
}

.login {
  img {
    width: 165px;
  }

  input {
    height: 42px;
    border: 1px solid #dfe4ea;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    font-size: 14px;
  }

  .card {
    border: 1px solid #dfe4ea94;
  }

  .btn {
    height: 44px;
  }

  .form-group {
    label {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    }
  }
}

.forgot-password {
  font-family: var(--primary-font);
  font-weight: 500;

  i {
    font-size: 13px !important;
    margin-right: 10px;
  }
}

.recaptcha {
  display: flex;
  align-items: center;
  justify-content: center;
}
