.followUpContainer{
  position: sticky;
  top: 94px;
  border: 1px solid hsl(0deg, 0%, 90%);
  border-radius: 7px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.10), 0 2px 4px -2px rgba(0,0,0,0.10);

  .wait {
    background-color: #e2e8f0;
    padding: 15px 20px 20px;
    margin-bottom: 0px !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    > label {
      font-weight: 700;
    }

    label, .afterText{ 
      color: black !important;
      font-family: var(--primary-font);
    }
  }

  .body {
    padding: 15px 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    z-index: 1000;
    position: relative;
    top: -7px;
    background: white;

    &.noDelay{
      top: 0px;
    }
  }

  .delayFields {
    display: flex;
    gap: 10px;
    align-items: center;
    
    label {
      margin: 0px;
    }

    > div:nth-child(2) {
      width: 75px;

      input {
        padding-right: 5px;
      }
    }

    > div:last-child {
      font-family: var(--primary-font);
      color: #94a3b8;
      font-weight: 500;
      font-size: 0.8rem;
    }

    .formGroup {
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
    }
  }

  .buttonsContainer{
    display: flex;
    gap: 10px;
  }

  .testContainer {
    padding: 20px 22px;
    background: white;
    border: 1px solid #94a3b8;
    border-radius: 8px;

    .grid{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }

    .formGroup {
      display: flex;
      flex-direction: column;
    }
  }

  .formGroup {
    margin-bottom: 30px;
  }

  .buttonsFlex {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 10px;

    button {
      background: var(--third-color);
      color: white;
      font-family: var(--primary-font);
      font-weight: 600;
      border-radius: 4px;
      font-size: 12px;
      padding: 3px 7px;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
        #4953fa 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;

      &.testEmail {
        background: #57606f;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
          rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
          #57606f 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
          rgba(0, 0, 0, 0) 0px 0px 0px 0px,
          rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
      }
    }
  }

  label {
    font-weight: 600;
    font-family: var(--primary-font);
    margin-bottom: 5px;
    font-size: 13px;
    color: var(--main-dark-color);

    span {
      color: #a3a3a3;
      font-size: 11px;
    }
  }

  .content {
    min-height: 200px;
    margin-bottom: 10px;

    &.error{
      > div {
        border-color: red !important;
      }
    }
  }

  .subject{
    height: 65px;

    > div {
      > div {
        min-height: auto !important;
      }
    }

    &.error{
      > div {
        border-color: red !important;
      }
    }

    .ql-editor{
      min-height: auto;
    }
  }
}