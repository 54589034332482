.home {
  .container {
    padding: 3px 0px;
  }
  .header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .button-header {
      button {
        padding: 5px 12px;
        border-radius: 25px;
        line-height: 10px;
        font-weight: 800;
        font-size: 12px;
      }
    }
  }
  .title-status {
    font-size: 11px;
    color: #a0a0a0;
  }
  .information {
    font-size: 12px;
    margin-top: 10px;
    font-family: var(--primary-font);

    i {
      font-size: 11px !important;
    }
  }
  section {
    margin-top: 0px;

    h2 {
      font-family: var(--primary-font);
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      margin-top: 15px;
      color: var(--main-dark-color);
    }

    .campaign_id {
      font-weight: 600;
      color: #57606f;
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      font-family: var(--primary-font);
    }

    .campaign_sender {
      font-weight: 500;
      color: #57606f;
      font-size: 12px;
      font-family: var(--primary-font);
      -webkit-font-smoothing: antialiased;

      b {
        font-weight: 600;
        color: var(--main-dark-color);
      }
    }

    .shortcuts {
      margin: 10px 0px;
      margin: 10px 0px;
      border-top: 1px solid #ebebeb;
      padding: 10px 0px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      gap: 10px;

      .item {
        background: #d52d1b;
        display: inline-block;
        color: white;
        padding: 5px 10px;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        font-family: var(--primary-font);
      }
    }

    .campaigns {
      h3 {
        font-family: var(--primary-font);
        font-size: 16px;
        color: #d52d1b;
      }
    }
  }
}
