.icons {
  i {
    font-size: 18px;
    margin-right: 10px;
    cursor: pointer;

    &.fa-check-circle {
      color: #2ed573;
    }

    &.fa-times-circle {
      color: #ff4757;
    }
  }
}

.emails-templates-box {
  font-size: 10px;
  cursor: pointer;

  .pending_emails {
    background-color: #f1b44c;
    width: 13px;
    height: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    font-weight: 900;
    color: white;
  }
}

td {
  p {
    position: relative;
  }
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: -50px;
  width: 70%;
  padding: 10px;
  background: #556ee6;
  color: #fff;
  font-size: 14px;
  white-space: break-spaces;
  z-index: 1000;
}

.admin__linkedin-searchs-page {
  h4 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    color: var(--main-dark-color);
    text-transform: initial;
  }

  .page-title-right {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    color: var(--main-dark-color);
  }

  .form-group {
    margin-bottom: 20px;
  }

  table {
    width: 100%;
    margin-bottom: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    font-size: 12px;

    .check {
      color: #4cd137;
      font-size: 15px;
    }

    .wrong {
      color: #e84118;
      font-size: 15px;
    }

    .pending {
      color: #f9ca24;
      font-size: 15px;
    }
  }

  .form-group {
    input[type="text"],
    select {
      height: 35px;
      border: 1px solid #dfe4ea;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
      font-size: 14px;
    }

    select {
      padding-top: 5px;
    }

    textarea {
      border: 1px solid #dfe4ea;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
      font-size: 13px;
    }
  }

  label {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    color: var(--main-dark-color);
    font-weight: 600;
  }
}
