.emailContainer {
  margin-bottom: 30px;

  h4 {
    font-size: 16px;
    font-weight: 500;
    font-family: var(--primary-font);
    color: var(--third-color);
  }
  .boxes {
    display: flex;
    // gap: 10px;
    flex-wrap: wrap;

    .box {
      border: 1px solid #ededed;
      // --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      //   0 4px 6px -2px rgba(0, 0, 0, 0.05);
      // box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      //   var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      padding: 10px 20px;
      // border-radius: 8px;
      min-width: 150px;
      position: relative;
      flex: 1;

      .number {
        font-weight: 700;
        font-family: var(--primary-font);
        color: var(--dark);
        font-size: 25px;
        display: flex;
      }

      label {
        font-family: var(--primary-font);
        font-weight: 400;
        color: #a4b0be;
      }

      .percentage {
        position: absolute;
        right: 5px;
        top: 3px;
        color: #20bf6b;
        font-weight: 600;
        font-family: var(--primary-font);
        font-size: 13px;
      }
    }
  }
}
