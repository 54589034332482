.campaign {
  .container {
    padding: 10px 0px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;

    @media screen and (max-width: 575px) {
      flex-direction: column;
      gap: 10px;
    }

    &__info {
      h1 {
        font-size: 20px;
        font-weight: 600;
        color: #2f3542;
        margin-bottom: 0px;
        line-height: normal;
      }

      h2 {
        font-size: 12px;
        font-weight: 600;
        color: #a4b0be;
        margin-bottom: 0px;
        line-height: normal;
      }
    }

    &__actions {
      display: flex;
      gap: 10px;
      
      @media screen and (max-width: 380px) {
        align-items: baseline;
        flex-wrap: wrap;
      }
    }
  }

  &-path {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    flex-direction: column;
  }

  .line {
    width: 2px;
    height: 30px;
    background-color: #a4b0be;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;

    &.small {
      height: 10px;
    }

    .new-node {
      width: 10px;
      height: 10px;
      padding: 0px;
      background-color: #d0d0d0;
      border-radius: 50%;
      position: absolute;
      border: 1px solid #bababa;
      transition: 0.5s all;

      &:hover {
        cursor: pointer;
        background-color: #bababa;
      }

      &.drop-box {
        width: 25px;
        height: 15px;
        border-radius: 4px !important;
        background: #a5f3de;
        border: 1px dashed #18b48a;
      }

      &.top {
        top: 10px;
      }

      &.bottom {
        bottom: 10px;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .line-open-2 {
    position: relative;
    width: auto;
    height: 35px;
    background-color: transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 2px solid #a4b0be;
    border-left: 2px solid #a4b0be;
    border-right: 2px solid #a4b0be;
  }

  .line-close-2 {
    width: auto;
    height: 35px;
    background-color: transparent;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 2px solid #a4b0be;
    border-left: 2px solid #a4b0be;
    border-right: 2px solid #a4b0be;
  }

  .two-path {
    display: grid;
    grid-template-columns: min-content min-content;
    justify-content: space-between;
    border-left: 2px solid #a4b0be;
    border-right: 2px solid #a4b0be;

    .left {
      position: relative;
      left: -51px;
    }

    .right {
      position: relative;
      right: -51px;
    }
  }
}

.dndflow {
  flex-direction: column;
  display: flex;
  height: 500px;
  width: 100%;
  border: 1px solid #f0f0f0;
  background: #f1f2f6;
  position: relative;
  border-radius: 6px;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.edgebutton {
  background: #eee;
  border: 1px solid #cdcdcd;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  height: 20px;
  line-height: 1;
  width: 20px;
}

.edgebutton-foreignobject > div {
  align-items: center;
  background: transparent;
  background-color: transparent !important;
  display: flex;
  height: 40px;
  justify-content: center;
  min-height: 40px;
  width: 40px;
}

.dndflow aside > * {
  margin-bottom: 10px;
  cursor: grab;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 180px;
  }
}

.react-flow__node-email {
  bottom: 1px solid;
}

.react-flow__node-emailSelector {
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 150px;
  background: #4953fa;
  font-family: var(--primary-font);
  font-weight: 600;
  border: 1px solid #4953fa;
  border-radius: 12px;
  color: white;

  &.selected {
    border-color: #eb3f41;
    background: #e7181c;
  }
}

.start,
.end {
  width: 55px !important;
  height: 55px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  background: #faa307 !important;
  color: white !important;
  font-family: var(--primary-font);
  font-weight: 700;
  border: 2px solid hsl(39deg, 96%, 48%) !important;
}

.react-flow__node-delaySelector {
  border-radius: 25px !important;
  border: 2px solid blue !important;
  background-color: white;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 150px;
  font-family: var(--primary-font);
  font-weight: 600;

  &.selected {
    border-color: #eb3f41 !important;
  }
}

.email {
  border: 2px solid purple !important;
}

.react-flow__handle-top {
  height: 8px !important;
  top: -9px !important;
  border-radius: 0px !important;
  background: #babac5 !important;
  width: 15px !important;
  border: none !important;
  // border-top: 0px;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.react-flow__handle-bottom {
  height: 8px !important;
  bottom: -9px !important;
  border-radius: 0px !important;
  background: #babac5 !important;
  width: 15px !important;
  border: none !important;
  // border-top: 0px;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.interception {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  top: -9px;
  background-color: #f1c40e;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    .options {
      display: block;
    }
  }

  .options {
    display: none;
    background: #ffffff;
    position: absolute;
    z-index: 10000;
    width: 120px;
    top: 10px;
    border-radius: 7px;
    padding: 5px 0px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    &:hover {
      display: block;
    }

    hr {
      margin: 5px 0px;
    }

    .item {
      padding: 1px 10px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
      color: #57606f;

      &:hover {
        color: #e64d3c;
      }
    }
  }
}
