.selected {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background: #f1f2f6;
  margin-bottom: 10px;
  padding: 5px 15px;
  align-items: center;

  span {
    font-family: var(--primary-font);
    font-weight: 600;
  }

  button {
    background: #d52d1b;
    color: white;
    font-family: var(--primary-font);
    font-weight: 600;
    border-radius: 4px;
    padding: 5px 15px;
  }
}

.completedIcon {
  i {
    color: #34d399 !important;
    margin-left: 5px;
    font-size: 0.7rem;
  }
}

.nameExtraInfo {
  & > * {
    font-weight: 600;
    cursor: pointer;
  }
}

.premium {
  color: #3742fa;
  font-size: 11px;
  font-family: var(--primary-font);
  font-weight: 500;
  background: hsl(237deg, 95%, 95%);
  border-radius: 30px;
  height: 17px;
  width: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  position: relative;
}

.buyPremium > i{
  color: #e81821 !important;
  // margin-left: 5px;
  // margin-top: 1px;
  font-size: 16px;
}

.buyPremium {
  border-radius: 50%;
  height: 16px;
  

  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  // margin: 10px;
  // height: 20px;
  // width: 20px;
  transform: scale(1);
  animation: pulse-red 2s infinite;
  margin-top: 1px;
  margin-left: 5px;
  position: relative;

  & > div {
    width: 200px;
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
