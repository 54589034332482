.homeTabs{
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebeef1;
  position: sticky;
  top: 88px;
  z-index: 100;
  background: white;

  .buttonCampaign{
    background: #4953fa;
    color: white;
    padding: 2px 10px 2px 3px;
    margin-top: 6px;
    border-radius: 25px;
    font-weight: 500;
    font-family: var(--primary-font);
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.5s;

    &:hover {
      scale: 1.05
    }
    
    &:disabled {
      background: gray !important;
    }

    &.broadcast {
      background: #e81821;
    }

    i {
      color: white !important;
      font-size: 1rem !important;
    }
  }

  .tab {
    border-bottom: 2px solid transparent;
    cursor: pointer;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
    opacity: 0.5;
    border-right: 1px solid #f0f0f0 !important;

    .nameTab{
      position: relative;
      font-family: var(--primary-font) !important;
      font-size: 16px !important;
      color: var(--main-dark-color) !important;
      font-weight: 700;
      line-height: normal;
      display: inline-block;

      .beta{
        color: white;
        right: -60px;
        top: -5px;
        position: absolute;
        background: #f7ab16e8;
        padding: 2px 12px;
        border-radius: 10px;
        font-weight: 700;
      }
    }

    

    i {
      font-size: 25px;
    }

    &:hover{
      background: hsl(0deg,0%,94%);
    }

    &.active {
      border-color: var(--third-color);
      opacity: 1;

      &.red {
        border-color: #e81821;

        i {
          color: #e81821;
        }
      }

      i {
        color: var(--third-color);
      }
    }
  }
}

.broadcastList{
  display: flex;
  flex-direction: column;
  gap: 10px;

  .details {
    height: 115px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .broadcastAdd {
    background: #f3f3f3a3;
    width: 100%;
    color: var(--main-dark-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 115px;
    border-radius: 0px;
    border: 2px dashed #5c5c5c;
    cursor: pointer;
    transition: 0.5s background;
    grid-gap: 15px;
    gap: 15px;
    font-weight: 600;
    font-family: var(--primary-font);
    font-size: 15px;
    margin-top: 10px;

    &:hover {
      background: #d52d1b;
      border-color: #d52d1b;
      color: white;

      i {
        color: white;
      }
    }
  }
}

.upgrade {
  font-size: 0.7rem;
  display: inline-flex;
  gap: 7px;
  margin-left: 10px;
  padding: 5px 10px 6px;
  position: relative;
  border-radius: 16px;
  top: -2px;
  align-items: center;
  background-color: #f0f2f5;

  i {
    color: #f1c40f;
  }
}