.title{
  font-family: var(--primary-font);
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  gap: 15px;
}

.followUpsContainer {
  display: grid;
  grid-template-columns: 300px 1fr ;
  gap: 20px;
  margin-bottom: 100px;

  .followUpsScroll {
    height: 100%;
  }

  @media screen and (max-width: 550px) {
    .followUpsScroll {
      display: inline-flex;
      gap: 10px;
    }

    .followColumn {
      width: 100%;
      overflow: auto;
    }

    .followUpBox {
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .addFollowButton {
      width: 250px;
      margin-bottom: 10px;
    }
  }


  @media screen and (max-width: 1280px) {
    grid-template-columns: 250px 1fr 225px;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 250px 1fr;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 175px 1fr;
  }

  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
  }
}

.tips {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.librarySubTitle {
  font-size: 0.9rem;
  font-family: var(--primary-font);
  font-weight: 400;
  line-height: normal;
  margin-top: 3px;
  color: #8b8b8b;
}

.tipsContainer {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comingSoon {
  background: #eef2f6;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 11px;
  border-radius: 5px;
  font-family: var(--primary-font);
  font-weight: 600;
  color: #0f172a;
}

.addFollowButton {
  width: 100%;
  border: 1px solid hsl(0deg, 0%, 90%);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  font-family: var(--primary-font);
  font-weight: 600;

  &.hideButton {
    display: none;
  }
}

.opacity {
  opacity: 0.3;
}

.followUpBox {
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid hsl(0deg, 0%, 90%);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.10), 0 2px 4px -2px rgba(0, 0, 0, 0.10);
  cursor: pointer;

  &.redBorder {
    border-color: #e80000;

    .headerIcon {
      background-color: #ffcccc !important;
      color: red;
    }
  }

  .boxContent {
    margin-bottom: 20px;
  }

  &.active {
    border-color: #b4b8fd;
    position: sticky;
    top: 94px;
    background: white;
    z-index: 10;
    opacity: 1;
    bottom: 15px;
  }

  .subjectHeader {
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
    font-family: var(--primary-font);
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    color: #4953fa;
    justify-content: space-between;

    .headerIcon {
      background: #ede9fe;
      padding: 5px 15px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .buttonPosition {
      display: flex;
      gap: 9px;
      color: #94a3b8;

      i:hover {
        color: #ff4d4f;
      }
    }
  }

  .footerBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    i {
      color: #94a3b8;
    }

    i:hover {
      color: #ff4d4f;
    }
  }

  .subjectBox {
    padding-top: 10px;

    label {
      font-size: 0.7rem;
      font-weight: 600;
      font-family: var(--primary-font);
      margin: 0px;
    }

    .subjectTitle {
      color: #0f172a;
      font-weight: 600;
      font-family: var(--primary-font);
      font-size: 1rem;
    }
  }

  .delayBox {
    padding-top: 10px;

    label {
      font-size: 0.7rem;
      font-weight: 600;
      font-family: var(--primary-font);
      margin: 0px;
    }

    .delayTitle {
      color: #0f172a;
      font-weight: 600;
      font-family: var(--primary-font);
      font-size: 0.7rem;

      span {
        color: #94a3b8;
      }
    }
  }
}