.tabContainer {
  h2 {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
  }

  h3 {
    color: black;
    font-size: 14px;
    font-family: var(--primary-font);
    font-weight: 600;
  }

  .formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    label {
      font-weight: 600;
      font-family: var(--primary-font);
      margin-bottom: 5px;
      font-size: 13px;
      color: var(--main-dark-color);
    }

    input {
      height: 28px;
      padding: 0 7px;
      border: 1px solid #e0e1e4;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      font-family: var(--primary-font);
    }
  }

  button {
    background: none;
    height: 28px;
    border-radius: 5px;
    padding: 0px 13px;
    transition: 0.75s background;
    // border: 1px solid #d6d7db;
    font-family: var(--primary-font);
    font-weight: 500;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  }
}

.myPurchases {
  // max-width: 380px;

  .noCard{
    background: #e1e1e1;
    padding: 8px 10px;
    border-radius: 4px;
    font-weight: 600;
    font-family: var(--primary-font);
  }

  .purchaseGrid {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
      padding: 9px 9px;
    border-radius: 5px;
    align-items: center;

    .amount {
      span {
        color: var(--primary);
        font-weight: 700;
      }
    }

    .date {
      color: gray;
      font-size: 0.7rem;
      font-family: var(--primary-font);
    }

    span {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: var(--primary-font);
      font-weight: 600;
      color: var(--dark);
    }

    i {
      font-size: 24px;
      color: black;
    }

    a {
      i {
        color: var(--primary);
        font-size: 1.3rem;
      }
    }

  }
}

.buttonMargin {
  margin: 20px 0px;
}

.footer {
  font-family: var(--primary-font);
  font-weight: 400;
  color: gray;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 35px;

  i {
    font-size: 30px;
    color: black;
  }
}
