.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

#features-modal {
  display: block !important;

  h1 {
    font-size: 16px;
    line-height: normal;
    text-align: center;
    font-family: var(--primary-font);
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 20px;
    color: black;
    b {
      color: #d52d1a;
    }
  }
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 80px auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  max-width: 650px; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  z-index: 10;
  font-size: 28px;
  font-weight: 300;
  position: absolute;
  right: 10px;
  top: 10px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.buttons {
  button {
    margin-right: 10px;
  }
}

.form-control:disabled {
  background-color: #e8e8e8;
  opacity: 1;
}

.btn-primary:disabled {
  cursor: not-allowed;
}

.required {
  font-size: 10px;
  color: #a4b0be;
}

.emails-templates-list {
  display: flex;
  justify-content: space-between;
  background-color: #f1f2f6;
  border-radius: 25px;
  padding: 4px 20px;
  align-items: center;
}

.pending-emails {
  font-size: 18px;
  margin-bottom: 20px;
}

#linkedInModal {
  .modal-content {
    max-width: 450px;

    h2 {
      color: #e74c3c;
      text-align: center;
      margin: 20px 0px 20px;
      font-weight: 600;
    }

    input {
      background-color: #f0f3f5;
      text-align: center;
      border: none;

      &::placeholder {
        color: #979ea0;
      }
    }

    label {
      text-align: center;
      width: 100%;
      font-weight: 600;
    }

    #many-profiles {
      width: 100px;
      margin: 0 auto;
      font-size: 20px;
      font-weight: 700;
      padding: 0px 10px 0px 20px;
      line-height: 10px;

      @media screen and (max-width: 900px) {
        padding: 0px 10px;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;

      button {
        background-color: #e74c3c;
        color: white;
        width: 100%;
        max-width: 225px;
        margin-top: 15px;
        margin-bottom: 10px;
        border: none;
        height: 40px;
        border-radius: 20px;
        font-size: 17px;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        -webkit-font-smoothing: antialiased;
        --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        justify-content: center;
      }
    }
  }
}

#ownList {
  .modal-content {
    max-width: 620px;

    h2 {
      color: #e74c3c;
      text-align: center;
      margin: 20px 0px 20px;
      font-weight: 600;
    }

    input {
      background-color: white;
      text-align: left;
      border: 1px solid #ced6e0;
    }

    input[type="file"] {
      height: 45px;
    }

    label {
      text-align: left;
      width: 100%;
      font-weight: 600;
    }

    .bandge {
      margin-top: 10px;
    }

    #many-profiles {
      width: 100px;
      margin: 0 auto;
      font-size: 20px;
      font-weight: 700;
      padding: 0px 10px 0px 20px;
      line-height: 10px;

      @media screen and (max-width: 900px) {
        padding: 0px 10px;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;

      button {
        background-color: #e74c3c;
        color: white;
        width: 100%;
        max-width: 225px;
        margin-top: 15px;
        margin-bottom: 10px;
        border: none;
        height: 40px;
        border-radius: 20px;
        font-size: 17px;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        -webkit-font-smoothing: antialiased;
        --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        justify-content: center;
      }
    }

    .download-sample {
      background-color: #f1f2f6;
      display: inline;
      padding: 3px 10px;
      border: none;
      border-radius: 25px;
      color: #747d8c;
      font-size: 12px;
      font-weight: 400;

      &:hover {
        cursor: pointer;
        background-color: #e8e9ee;
      }
    }

    .preview-box {
      overflow: auto;
    }

    table {
      width: 700px;
      margin-bottom: 15px;

      th,
      td {
        font-size: 11px;
        border: 1px solid #eaeaea;
        padding: 3px 5px;
      }

      td {
        color: gray;
      }
    }
  }
}

#loadingModal {
  .modal-content {
    max-width: 450px;

    h2 {
      color: #e74c3c;
      text-align: center;
      margin: 20px 0px 20px;
      font-weight: 600;
    }

    input {
      background-color: #dfe6e9;
      text-align: center;
      border: none;

      &::placeholder {
        color: #979ea0;
      }
    }

    label {
      text-align: center;
      width: 100%;
      font-weight: 600;
    }

    #many-profiles {
      width: 100px;
      margin: 0 auto;
      font-size: 20px;
      font-weight: 700;
      padding: 0px 10px 0px 20px;
      line-height: 10px;

      @media screen and (max-width: 900px) {
        padding: 0px 10px;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;

      button {
        background-color: #e74c3c;
        color: white;
        width: 100%;
        max-width: 225px;
        margin-top: 15px;
        margin-bottom: 10px;
        border: none;
        height: 40px;
        border-radius: 20px;
        font-size: 17px;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        -webkit-font-smoothing: antialiased;
        --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      }
    }
  }
}

#buyCreditsModal {
  .modal-content {
    max-width: 450px;

    .credits-text {
      margin-top: 0px;
      margin-bottom: 20px;
    }

    h2 {
      color: #e74c3c;
      text-align: center;
      margin: 20px 0px 0px;
      font-weight: 600;
    }

    input {
      // background-color: #dfe6e9;
      // text-align: center;
      border: none;
      // padding: 0px 10px;
      padding-left: 10px;
      padding-right: 10px;
      outline: none;

      &::placeholder {
        color: #979ea0;
      }
    }

    // label {
    //   text-align: center;
    //   width: 100%;
    //   font-weight: 600;
    // }

    #many-profiles {
      width: 100px;
      margin: 0 auto;
      font-size: 20px;
      font-weight: 700;
      padding: 0px 10px 0px 20px;
      line-height: 10px;

      @media screen and (max-width: 900px) {
        padding: 0px 10px;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;

      button {
        background-color: #e74c3c;
        color: white;
        width: 100%;
        max-width: 225px;
        margin-top: 15px;
        margin-bottom: 10px;
        border: none;
        height: 40px;
        border-radius: 20px;
        font-size: 17px;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        -webkit-font-smoothing: antialiased;
        --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      }
    }
  }

  @keyframes fade {
    from {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
  }

  .Form {
    margin: 20px 0px;
    animation: fade 200ms ease-out;
  }

  .FormGroup {
    padding: 0;
    border-style: none;
    background-color: #fff;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #dfe4ea;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .FormGroupCard {
    // background-color: #7795f8;
  }

  .FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #ced6e0;
  }

  .FormRow:first-child {
    border-top: none;
  }

  .FormRowLabel {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    color: #57606f;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0px;
  }

  @keyframes void-animation-out {
    0%,
    to {
      opacity: 1;
    }
  }
  .FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: #57606f;
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
  }

  .FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #2f3542;
    background-color: transparent;
    animation: 1ms void-animation-out;
  }

  .InputElement {
    color: #2f3542;
  }

  .FormRowInput::placeholder {
    color: #979ea0;
  }

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }

  .SubmitButton {
    background-color: #e74c3c;
    color: white;
    width: 100%;
    max-width: 225px;
    margin-top: 15px;
    margin-bottom: 10px;
    border: none;
    height: 40px;
    border-radius: 20px;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    -webkit-font-smoothing: antialiased;
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    font-weight: 600;
    display: block;
    margin: 20px auto 0px;
  }

  .SubmitButton:active {
    background-color: #e74c3c;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #e74c3c;
    transform: scale(0.99);
  }

  .SubmitButton.SubmitButton--error {
    transform: translateY(15px);
  }
  .SubmitButton.SubmitButton--error:active {
    transform: scale(0.99) translateY(15px);
  }

  .SubmitButton:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #e74c3c;
    box-shadow: none;
  }

  .ErrorMessage {
    color: #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    margin-top: 0px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
  }

  .ErrorMessage svg {
    margin-right: 10px;
  }

  .Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
  }

  .ResultTitle {
    color: #fff;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
  }

  .ResultMessage {
    color: #9cdbff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
  }

  .ResetButton {
    border: 0;
    cursor: pointer;
    background: transparent;
  }
}

#single-lead-modal {
  .modal-content {
    max-width: 450px;

    h2 {
      color: #e74c3c;
      text-align: center;
      margin: 20px 0px 20px;
      font-weight: 600;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;

    button {
      background-color: #e74c3c;
      color: white;
      width: 100%;
      max-width: 225px;
      margin-top: 15px;
      margin-bottom: 10px;
      border: none;
      height: 40px;
      border-radius: 20px;
      font-size: 17px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      -webkit-font-smoothing: antialiased;
      --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      justify-content: center;
    }
  }
}

.credits-text {
  text-align: center;
  margin-top: 10px;
}

.credits-error {
  text-align: center;
  color: red;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
}

.grid {
  display: grid;
  gap: 10px;

  &.cols-3 {
    grid-template-columns: repeat(3, 1fr);

    .option {
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      h4 {
        font-size: 0.9rem;
        margin-bottom: 0px;
      }

      h3 {
        font-weight: 800;
        color: #e74c3c;
        font-size: 1.5rem;
        margin-bottom: 0px;
      }

      &.active {
        background-color: #e74c3c;

        h4 {
          color: white;
        }

        h3 {
          color: white;
        }
      }
    }
  }
}

.position-relative {
  position: relative;
  margin-bottom: 10px;
}

.how-it-works {
  text-decoration: underline;
  bottom: 5px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: 123px;
  font-size: 12px;
  cursor: pointer;
}

.how-it-works-container {
  width: 100%;

  h2 {
    text-align: center;
    font-size: 20px;
    color: #e81a1f;
  }

  .how-it-works-scroll {
    max-height: 350px;
    overflow-y: auto;
    margin-bottom: 30px;
    height: 100%;

    h2 {
      color: black;
    }

    .text {
      margin-bottom: 10px;
    }

    img {
      width: 100%;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    height: 225px;
    display: flex;
    align-items: center;

    iframe {
      height: 225px;
    }
  }
}

#login-modal,
#features-modal {
  h2 {
    text-align: center;
    font-size: 20px;
    color: #e81a1f;
  }

  .steps-container {
    padding-top: 20px;
    width: 100%;

    .step-content {
      min-height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 20px;

      iframe {
        width: 100%;
      }
    }

    h2 {
      color: #57606f;
      font-size: 18px;
    }

    .text {
      text-align: center;

      a {
        color: #e81a1f;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    img {
      width: 80%;
      margin-bottom: 15px;
    }

    .buttons-container {
      display: grid;
      justify-content: center;
      grid-template-columns: auto auto;
      gap: 10px;
      margin-top: 20px;

      .buttons-navigation {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
      }

      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          text-decoration: underline;
          margin-top: 6px;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .btn-next {
        background-color: #e81a1f;
        color: white;
        border: none;
        font-size: 16px;
        padding: 7px 25px;
        border-radius: 25px;
        font-weight: bolder;
      }

      .btn-back {
        background-color: #57606f;
        color: white;
        border: none;
        font-size: 16px;
        padding: 7px 25px;
        border-radius: 25px;
        font-weight: bolder;
      }
    }
  }
}

#howItWorksLinkedinModal {
  .buttons {
    display: flex;
    justify-content: center;

    button {
      background-color: #e74c3c;
      color: white;
      width: 100%;
      max-width: 225px;
      margin-top: 15px;
      margin-bottom: 10px;
      border: none;
      height: 40px;
      border-radius: 20px;
      font-size: 17px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      -webkit-font-smoothing: antialiased;
      --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
      cursor: pointer;

      &:hover {
        background-color: #bf161a !important;
      }
    }
  }
}

.btn-secondary {
  background-color: #5d6071 !important;
  cursor: pointer;

  &:hover {
    background-color: #393b46 !important;
  }
}

.form-container {
  .header-modal {
    margin-bottom: 15px;
  }
}

.buttons-lead {
  margin-bottom: 10px;
}

.status {
  .unsubscribed {
    color: #e81a1f;
    font-weight: bold;
  }

  .active {
    color: #27ae60;
    font-weight: bold;
  }
}

.input-search {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;

  button {
    height: 36px;
  }
}

#hardReset {
  .validationTooltip {
    font-weight: 400 !important;
  }
}

#changeCreditsModal,
#changeLimitsModal {
  h2 {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, Ubuntu, sans-serif;
    font-size: 17px;
    font-weight: 600;
  }

  .modal-content {
    max-width: 400px;
  }

  .status-container {
    display: flex;
    align-items: center;
    label {
      margin: 0px;
      margin-left: 5px;
      font-family: var(--primary-font);
      font-size: 13px;
      font-weight: 600;
    }
  }
  .status-user {
  }
}

.buttons {
  display: grid;
  grid-template-columns: min-content min-content;

  button {
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    font-size: 14px;
    justify-content: center;

    &.btn-primary {
      &:disabled {
        background-color: #e81a1f96;
      }
    }
  }
}

.no-linkedin {
  text-align: center;
  color: #ff7979;
  font-size: 12px;
  margin-top: 2px;
}

.campaign-modal {
  .buttons {
    justify-content: flex-start !important;
  }

  input.form-control {
    font-family: var(--primary-font);
    font-weight: 600;
  }

  h2 {
    margin: 10px 0px;
    font-weight: 600;
    font-size: 28px;
  }
}
