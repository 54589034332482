.unsuscribed {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 25px;
    font-family: var(--primary-font);
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .icon {
    i {
      font-size: 45px;
    }
  }

  .email {
    border: none;
  }
}
