.toggleContainer {
  display: flex;
  gap: 10px;
  align-items: center;

  .text {
    font-weight: 600;
    font-family: var(--primary-font);
    font-size: 13px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
    margin-bottom: 0px !important;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ced6e0;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 17px;
      width: 17px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2ecc71;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2ecc71;
    }

    input:checked + .slider:before {
      transform: translateX(20px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}
