.widget {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  background: white;
  font-weight: 600;
  position: relative;
  transition: 0.5s background;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 10px;
  border: 1px solid #ededed;

  button {
    background: #ff4d4e;
    padding: 7px 10px;
    color: white;
    font-family: var(--primary-font);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    transition: 0.5s all;
    font-size: 12px;
    position: relative;

    i {
      font-size: 35px;
      color: white;
      margin-right: 5px;
      margin-left: 1px;
      cursor: default;
      position: absolute;
      right: 8px;
      opacity: 0.4;
      margin: 0px;
    }

    img {
      width: 30px;
      position: absolute;
      right: 9px;
      height: auto;
      margin: 0;
      filter: brightness(0) invert(1);
      opacity: 0.6;
      top: 6px;
    }

    &:hover {
      background: #ff0a0a;
    }

    &.linked {
      background: #004e89;

      &:hover {
        background: hsl(206, 100%, 25%);
      }
    }

    label {
      margin: 0px;
      font-size: 14px;
      font-weight: 700;
      font-family: var(--primary-font);
      line-height: normal;
    }
  }

  .title {
    font-weight: 600;
    font-family: var(--primary-font);
    font-size: 12px;
  }

  .label {
    font-weight: 600;
    font-family: var(--primary-font);
    font-size: 12px;
  }

  .total {
    font-weight: 800;
    font-family: var(--primary-font);
    color: var(--main-dark-color);
    font-size: 45px;
    line-height: initial;
  }

  .searchs {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .search {
      display: grid;
      grid-template-columns: 50px 1fr;
      // background: #f1f2f6;
      padding: 5px;
      border-radius: 5px;
      gap: 5px;
      cursor: pointer;

      &:hover {
        background: hsl(228, 22%, 96%);
      }

      .rate {
        font-family: var(--primary-font);
        font-weight: 700;
        font-size: 11px;
        display: flex;
        align-items: center;
        background: #f54d4e;
        padding: 5px;
        border-radius: 5px;
        color: white;
        justify-content: center;
      }

      .details {
        .name {
          font-weight: 600;
          font-family: var(--primary-font);
          color: var(--main-dark-color);
        }

        .status {
          font-weight: 700;
          font-family: var(--primary-font);
          font-size: 10px;
          text-transform: uppercase;

          &.completed {
            color: #20bf6b;
          }
        }
      }
    }

    .nothing {
      font-size: 14px;
      font-weight: 600;
      font-family: var(--primary-font);
      color: gray;
    }
  }
}
