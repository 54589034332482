.menu-container {
  padding: 0px;
  position: sticky;
  top: 41px;
  z-index: 10000;
  border-bottom: 1px solid hsl(0deg, 0%, 88%);
  background: #fbfbfb;
}

.container {
  padding: 0px;
}

.menu {
  padding: 10px 0px 10px;
  display: flex;
  gap: 10px;

  @media screen and (max-width: 800px) {
    display: none;
  }

  a {
    padding: 4px 10px;
    border-radius: 25px;
    font-weight: 600;
    font-family: var(--primary-font);
    color: #57606f;
    font-size: 13px;
    transition: 0.25s all;

    &.active {
      color: #57606f !important;
      background: #ebebeb;
    }

    &:hover {
      color: #57606f !important;
      background: #ebebeb;
    }

    &[aria-current]:not([aria-current="false"]) {
      color: #57606f !important;
      background: #ebebeb;

      span {
        font-size: 13px;
        font-weight: 600;
      }
    }

    span {
      font-size: 13px;
      font-weight: 600;
    }
  }

  .menu-sub {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-family: var(--primary-font);
    font-size: 13px;
    cursor: pointer;
    padding: 0px 10px;
    border-radius: 20px;
    transition: 0.25s all;

    &:hover {
      color: #57606f !important;
      background: #ebebeb;
    }

    .name-icon {
      display: flex;
      align-items: center;

      & > i {
        margin-left: 10px;
      }

      .iconName {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.popover-menu-campaign {
  position: absolute;
  z-index: 10000;
  background: white;
  border-radius: 4px;
  border: 1px solid #e2e3e7;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  h3 {
    font-size: 13px;
    font-family: var(--primary-font);
    font-weight: 600;
    letter-spacing: 0.1px;
    padding: 0px 20px;
    text-align: left;
    border-bottom: 1px solid gainsboro;
  }

  .options-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    padding: 0px 10px;

    .option {
      font-size: 13px;
      font-weight: 600;
      font-family: var(--primary-font);
      padding: 10px 5px;
      cursor: pointer;
      display: grid;
      grid-template-columns: 25px 1fr;
      align-items: center;

      i {
        margin-right: 10px;
        font-size: 16px;
      }

      .fa-address-book {
        color: #e81b1f;
      }

      .fa-chart-column {
        color: #4953fa;
      }

      .fa-diagram-nested {
        color: #1abc9c;
      }
    }
  }
}

.popover-menu-sender {
  position: absolute;
  z-index: 10000;
  background: white;
  border-radius: 4px;
  border: 1px solid #e2e3e7;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  h3 {
    font-size: 13px;
    font-family: var(--primary-font);
    font-weight: 600;
    letter-spacing: 0.1px;
    padding: 0px 20px;
    text-align: left;
    border-bottom: 1px solid gainsboro;
  }

  .options-container {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    padding: 0px 10px;

    .option {
      font-size: 13px;
      font-weight: 600;
      font-family: var(--primary-font);
      padding: 10px 5px;
      cursor: pointer;
      align-items: center;
      width: 130px;
      display: flex;

      i {
        margin-right: 10px;
        font-size: 16px;
      }

      .fa-address-book {
        color: #e81b1f;
      }

      .fa-chart-column {
        color: #4953fa;
      }

      .fa-diagram-nested {
        color: #1abc9c;
      }
    }
  }
}