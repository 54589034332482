.result {
  background: white;
  border: 1px solid #dcdcdc85;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  gap: 15px;
  cursor: pointer;

  &:hover {
    background: #efefef;
  }

  .icon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #dfe4ea;
    color: white;
    border-radius: 4px;
  }

  .time {
    font-size: 12px;
    font-weight: 600;
    font-family: var(--primary-font);
    -webkit-font-smoothing: antialiased;
    color: #bdbec1;
  }

  .search {
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 15px;
    color: #2c3a47;
  }
}
