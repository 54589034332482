.buyCreditModal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  .option {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    cursor: pointer;

    &.active {
      background: var(--third-color);
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
        var(--third-color) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;

      * {
        color: white;
      }
    }

    h4 {
      font-size: 10px;
      font-family: var(--primary-font);
      line-height: normal;
      font-weight: 600;
    }

    h3 {
      font-family: var(--primary-font);
      color: black;
      font-weight: 600;
      font-size: 28px;
      line-height: 42px;
    }
  }
}

.myCards {
  margin-top: 20px;

  label {
    font-family: var(--primary-font);
    font-weight: 700;
    position: relative;
    width: 100%;

    a {
      position: absolute;
      right: 0px;
      font-family: var(--primary-font);
      font-weight: 700;
      font-size: 12px;
      color: var(--third-color);
    }
  }
  .creditCardsGrid {
    display: grid;
    grid-template-columns: 35px 1fr;
    gap: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    padding: 5px;
    border-radius: 5px;

    &:hover {
      background-color: hsl(0, 0%, 96%);
    }

    &.active {
      background: var(--third-color);
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
        var(--third-color) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;

      * {
        color: white !important;
      }
    }

    i {
      font-size: 24px;
      color: black;
    }

    .number {
      font-family: var(--primary-font);
      font-weight: 600;
      color: black;
    }

    .expires {
      font-family: var(--primary-font);
      font-size: 12px;
    }
  }
}
