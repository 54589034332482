aside {
  position: absolute;
  right: 15px;
  top: 15px;
  max-width: 250px !important;
  width: 100% !important;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

h2 {
  font-size: 16px;
  font-family: var(--primary-font);
  line-height: initial;
  margin-top: 10px;
  color: var(--main-dark-color);
}

.description {
  text-align: center;
  font-family: var(--primary-font);
  font-weight: 400;
}
