.stats {
  section {
    margin-top: 15px;
    padding: 0px;
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
    color: var(--dark);
    letter-spacing: 0px;
    font-family: var(--primary-font);
  }

  h3 {
    font-weight: 700;
    font-family: var(--primary-font);
    font-size: 22px;
    color: var(--main-dark-color);
  }

  .general-info {
    font-weight: 600;
    color: #57606f;
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    font-family: var(--primary-font);
  }

  .stats-boxs {
    display: flex;
    flex-direction: column;
    // gap: 15px;
    margin: 20px 0px;

    @media screen and (max-width: 800px) {
      order: 2;
    }
  }

  .bigStats {
    border: 1px solid hsl(0deg, 0%, 93%);
    // --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    //   0 4px 6px -2px rgba(0, 0, 0, 0.05);
    // box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    //   var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding: 15px 20px;
    // border-radius: 8px;

    .name {
      font-family: var(--primary-font);
      font-weight: 400;
      color: #a4b0be;
    }

    .smallStats {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-top: 20px;
      gap: 10px;

      .number {
        font-weight: 700;
        font-family: var(--primary-font);
        color: var(--third-color);
        font-size: 20px;
        position: relative;

        .hideNumber {
          opacity: 0;
          position: absolute;
          top: 0px;
          width: 150px;
        }

        &:hover {
          .hideNumber {
            opacity: 1;
            transition: 0.5s opacity;
            background: #fbfbfb;
          }
        }
      }
    }
  }

  .bottomStats {
    display: flex;
    // gap: 20px;

    .box-stat {
      flex: 1;
    }
  }

  .bigBox {
    .number {
      font-size: 50px;
      font-weight: 700;
      color: var(--main-dark-color);
      font-family: var(--primary-font);
      line-height: normal;
      position: relative;

      .hideNumber {
        opacity: 0;
        position: absolute;
        top: 0px;
        width: 100%;
      }

      &:hover {
        .hideNumber {
          opacity: 1;
          transition: 0.5s opacity, 0.5s background;
          background: #fbfbfb;
        }
      }
    }
  }

  .stats-events-charts {
    label {
      margin: 0px;
      font-family: var(--primary-font);
      font-size: 13px;
    }
  }

  .fa-cloud-download {
    color: #27ae60;
    margin-left: 8px;
    cursor: pointer;
  }
}

.statsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}

.statsTable {
  h2 {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    color: var(--dark);
    letter-spacing: 0px;
    font-family: var(--primary-font);
    margin-bottom: 15px;
  }
}
