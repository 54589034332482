.tabContainer {
  max-width: 380px;

  .button {
    background: none;
    height: 28px;
    border-radius: 5px;
    padding: 0px 13px;
    transition: 0.75s background;
    // border: 1px solid #d6d7db;
    font-family: var(--primary-font);
    font-weight: 500;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  }

  h2 {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
  }

  .formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    label {
      font-weight: 600;
      font-family: var(--primary-font);
      margin-bottom: 5px;
      font-size: 13px;
      color: var(--main-dark-color);
    }

    p {
      font-weight: 600;
      font-family: var(--primary-font);
      margin-bottom: 5px;
      font-size: 12px;
      color: gray;
    }

    .error{
      color: red;
      font-family: var(--primary-font);
      margin-top: 4px;
      font-size: 0.8rem;
    }

    input {
      height: 28px;
      padding: 0 7px;
      border: 1px solid #e0e1e4;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      font-family: var(--primary-font);
    }

    .dailyLimits {
      color: #4953fa;
      font-weight: 600;
      font-family: var(--primary-font);
    }
  }
}

.guides {
  font-size: 12px;
  font-weight: 500;
  font-family: var(--primary-font);
  color: var(--primary-color);
  margin-top: 3px;

  &:hover {
    color: var(--primary-color);
  }
}