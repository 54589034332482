.widget {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  background: #4953fa;
  font-weight: 600;
  position: relative;
  transition: 0.5s background;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 10px;
  border: 1px solid #ededed;

  .title {
    font-weight: 600;
    font-family: var(--primary-font);
    font-size: 12px;
    color: white;
  }

  .subtitle {
    font-weight: 700;
    font-family: var(--primary-font);
    font-size: 60px;
    color: white;
    line-height: initial;
  }

  .label {
    font-weight: 600;
    font-family: var(--primary-font);
    font-size: 12px;
  }

  .total {
    font-weight: 800;
    font-family: var(--primary-font);
    color: var(--main-dark-color);
    font-size: 45px;
    line-height: initial;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    margin-top: 10px;

    button {
      background: #42d1a1;
      padding: 12px 10px;
      color: white;
      font-family: var(--primary-font);
      border-radius: 5px;
      flex-direction: column;
      transition: 0.5s all;
      font-size: 20px;
      position: relative;
      text-align: center !important;
      align-items: center;
      font-weight: 500;

      transition: margin-top 0.3s ease, margin-left 0.3s ease,
        box-shadow 0.3s ease;
      background: #42d1a1;
      border: solid 1px #2cb587;
      box-shadow: 1px 0px 0px #2cb587, 0px 1px 0px #2cb587, 2px 1px 0px #2cb587,
        1px 2px 0px #2cb587, 3px 2px 0px #2cb587, 2px 3px 0px #2cb587,
        4px 3px 0px #2cb587, 3px 4px 0px #2cb587, 5px 4px 0px #2cb587,
        4px 5px 0px #2cb587, 6px 5px 0px #2cb587, 5px 6px 0px #2cb587,
        7px 6px 0px #2cb587, 6px 7px 0px #2cb587, 8px 7px 0px #2cb587,
        7px 8px 0px #2cb587, 9px 8px 0px #2cb587, 8px 9px 0px #2cb587;

      b {
        font-weight: 800;
        color: #fefeff;
      }

      i {
        font-size: 35px;
        color: white;
        margin-right: 5px;
        margin-left: 1px;
        cursor: default;
        position: absolute;
        right: 8px;
        opacity: 0.4;
        margin: 0px;
      }

      img {
        width: 30px;
        position: absolute;
        right: 9px;
        height: auto;
        margin: 0;
        filter: brightness(0) invert(1);
        opacity: 0.6;
        top: 6px;
      }

      &:hover {
        // background: #2fc190;
        transition: margin-top 0.3s ease;
        margin-left: 0.3s ease;
        box-shadow: 0.3s ease;

        margin-left: 9px;
        margin-top: 9px;

        box-shadow: 0px 0px 0px #2fc190;
      }

      &.linked {
        background: #004e89;

        &:hover {
          background: hsl(206, 100%, 25%);
        }
      }

      label {
        margin: 0px;
        font-size: 14px;
        font-weight: 700;
        font-family: var(--primary-font);
        line-height: normal;
      }
    }
  }
}
