.navigationBody {
  .option {
    font-family: var(--primary-font);
    font-weight: 600;
    padding: 10px 20px;
    display: flex;
    gap: 20px;
    align-items: center;

    i {
      font-size: 20px;
      width: 25px;
    }

    &.active {
      color: #4953fa;
    }

    &:hover {
      color: #4953fa;
      cursor: pointer;
    }
  }
}
