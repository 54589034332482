.senderContainer {
  padding: 15px 20px;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  .name {
    font-weight: 600;
    color: var(--dark);
    font-size: 1rem;
    font-family: var(--primary-font);
  }

  .meta {
    font-family: var(--primary-font);
  }

  .metaError {
    font-size: 0.8rem;
    color: #ff4d4f;
    background: #ff4d4f29;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 600;
    font-family: var(--primary-font);
    margin-top: 5px;
  }

  .senderAction {
    button {
      padding: 0px 13px;
      height: 28px;
      transition: all 0.5s;
      background: transparent;

      &:hover {
        background: none;
        height: 28px;
        border-radius: 5px;
        padding: 0px 13px;
        transition: 0.75s background;
        // border: 1px solid #d6d7db;
        font-family: var(--primary-font);
        font-weight: 500;
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
          rgba(0, 0, 0, 0) 0px 0px 0px 0px,
          rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
          rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
          rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
          rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
        display: flex;
        align-items: center;
        color: var(--dark);
      }
    }
  }

  .popoverContainer {
    border: 1px solid #e2e3e7;
  }

  .verified{
    background: #d5f6e3;
    color: #2ecc71;
    font-weight: 600;
    padding: 2px 10px 3px;
    border-radius: 3px;
    font-size: 0.8rem;
  }
}