.users-heading {
  display: grid;
  grid-template-columns: auto min-content;
  margin-bottom: 5px;

  h3 {
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    font-size: 16px;
    font-weight: 700;
    color: var(--dark);

    span {
      display: flex !important;
      margin: 0px;
      gap: 6px !important;

      & > span {
        display: flex;
        align-items: center;
        margin: 0px;

        span {
          margin: 0px !important;
        }
      }
    }
  }

  .information-tooltip {
    color: #a4b0be;
    font-size: 15px;
    position: relative;
    top: 0px;
    right: -3px;
    cursor: pointer;
  }

  .qty-leads {
    background: #f0f0f0;
    padding: 2px 10px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 25px;
    margin-right: 5px;
    cursor: pointer;

    &.active {
      background: hsl(0deg, 0%, 84%);
    }

    &.linkedin-qty {
      position: relative;
      top: 2px;
    }

    i {
      cursor: pointer;
    }

    .linkedin-badge-container {
      background-color: #0077b4;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: white !important;
        font-size: 8px !important;
        position: absolute;
      }
    }

    .qty-number {
      color: #7b7979;
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.tooltip-box {
  line-height: normal;
}
