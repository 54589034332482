.tipContainer {
  padding: 10px;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid #dbdbdb;
  border-radius: 7px;

  .tipHeader {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    >div {
      font-weight: 500;
      font-family: var(--primary-font);
      color: #0f172a;
    }

    i {
      color: #16a34a;
    }
  }

  .tipContent {
    font-family: var(--primary-font);
    color: #64748b;

    a {
      color: var(--primary-color);
      text-decoration: underline !important;
    }

    ul, ol {
      list-style: initial;
      padding-left: 1rem;
    }

    ol {
      list-style: auto;
    }
  }
}