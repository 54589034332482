:root {
  --main-dark-color: #1a1f36;
  --primary-color: #e81821;
  --primary-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --secondary-color: #faa307;
  --third-color: #4953fa;
}

html {
  scroll-behavior: smooth;
  background: white;
}

body {
  height: 100%;
  min-height: 100vh;
}

.App {
  text-align: center;
}

.toaster {
  background-color: red;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Loading__bar___21yOt {
  height: 4px !important;
}

.Loading__spinner___11Pm4 {
  top: 9px !important;
}

.css-nvf14r-ToastContainer {
  z-index: 100000 !important;
}

input:-webkit-autofill {
  color: #57606f;
  -webkit-text-fill-color: #57606f;
}
input:autofill {
  color: #57606f;
  -webkit-text-fill-color: #57606f;
}

.ElementsApp.is-autofilled .InputElement:not(.is-empty),
.ElementsApp.is-autofilled .InputElement:-webkit-autofill {
  color: #57606f;
  -webkit-text-fill-color: #57606f;
}

#sidebar-menu ul li a {
  padding: 1px 1.5rem !important;
  display: flex !important;
  align-items: center !important;
}

#sidebar-menu ul li a i {
  font-size: 12px !important;
  color: #ced6e0 !important;
  position: relative;
  top: 0px;
}

#sidebar-menu ul li .badge {
  margin-top: 1px;
  margin-left: 6px;
}

.page-content {
  background: white;
}

.react-autocomplete-input > li {
  cursor: pointer;
  padding: 3px 10px !important;
  min-width: 100px;
  font-weight: 500;
  font-family: var(--primary-font);
  font-size: 13px;
}

.react-autocomplete-input > li.active {
  background-color: #3a42c8 !important;
  border-radius: 4px;
}

.react-autocomplete-input {
  border: none !important;
  padding: 0px !important;
  border-radius: 4px;
}

.container {
  max-width: 90rem;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.ql-editor {
  /* min-height: auto; */
  min-height: 200px;
}

.ql-toolbar{
  margin-bottom: 10px;
}

.ql-snow {
  font-family: var(--primary-font);
  border-radius: 8px;
  border: 1px solid #e2e8f0 !important;
  background: white;
}

.grecaptcha-badge{
  display: none;
}