.templateContainer {
  padding: 10px;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid #dbdbdb;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;

  .templateHeader {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    >div {
      font-weight: 500;
      font-family: var(--primary-font);
      color: #0f172a;
    }

    i {
      color: #ff4d4f;
    }
  }

  .tipContent {
    font-family: var(--primary-font);
    color: #64748b;
  }

  .official {
    color: #22b959;
    background: #d8f8e4;
    padding: 2px 10px;
    border-radius: 20px;
    margin-top: 2px;
    display: inline-block;
  }

  .description{
    color: #94a3b8;
    margin-top: 5px;
  }

  button {
    justify-content: center;
  }

  .buttonContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    button:first-child {
      flex: 1;
    }
  }
}