.auth {
  background: white;

  h1 {
    // font-family: var(--primary-font);
    color: black;
    line-height: 2.3rem;
    font-size: 2.3rem;
    font-weight: 600;
    // margin-bottom: 40px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
  }

  h2 {
    text-align: left;
    width: 100%;
    margin: 0px;
    // font-family: var(--primary-font);
    color: #a4b0be;
    font-weight: 500;
    font-size: 0.9rem;
    font-family: 'Poppins', sans-serif;
  }
}

.authGrid{
  min-height: 100vh;
  display: grid;
  grid-template-columns: 500px 1fr;

  @media screen and (max-width: 525px) {
    grid-template-columns: 1fr;
  }

  .formColumn {
    padding: 35px 35px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    img {
      max-width: 150px;
      margin: 0px;
    }

    .formContainer {
      // height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      p {
        width: 100%;
        font-family: var(--primary-font);
        font-weight: 500;
        margin-top: 20px;

        a {
          color: #000;
          font-weight: 700;
        }
      }

      form {
        width: 100%;
        margin-top: 40px;

        .button {
          background: #e81821;
          color: white;
          // font-family: var(--primary-font);
          font-weight: 700;
          width: 100%;
          height: 45px;
          border-radius: 3px;
          max-width: 400px;
          margin-top: 20px;
          font-family: "Poppins", sans-serif;
        }
      }

      .formGroup {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        input {
          height: 45px;
          max-width: 400px;
          width: 100%;
          border: 1px solid #ebebeb;
          border-radius: 3px;
          padding: 10px;
          font-family: var(--primary-font);

          &.error{
            border-color: #e81821;
          }
        }
      }
    }
  }

  .imageColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e81821;

    img {
      width: 75%;
      margin: 0px;
      max-width: 540px;

      @media screen and (max-width: 800px){
        display: none;
      }
    }
  }
}