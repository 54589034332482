.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .premium {
    color: #3742fa;
    font-size: 11px;
    font-family: var(--primary-font);
    font-weight: 500;
    background: hsl(237deg, 95%, 95%);
    border-radius: 30px;
    padding: 1px 6px 2px;
  }

  .premium {
    color: white;
    font-size: 11px;
    font-family: var(--primary-font);
    font-weight: 500;
    background: hsl(237deg, 95%, 95%);
    border-radius: 5px;
    padding: 1px 6px 2px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;

    .item {
      padding: 5px 10px;
      background-color: #3742fa;
      border-radius: 5px;
      font-family: var(--primary-font);
      font-weight: 600;
    }
  }

  label {
    font-weight: 600;
    font-family: var(--primary-font);
    margin-bottom: 5px;
    font-size: 13px;
    color: var(--main-dark-color);
    margin: 0px 15px;
    color: black !important;

    &.premiumFields {
      display: flex;
      align-items: center;
      gap: 6px;
      font-weight: 500;
      color: #3a43c8;
    }

    &.checkboxField {
      display: flex;
      align-items: center;
      gap: 6px;
      font-weight: 500;
    }
  }

  input {
    height: 28px;
    padding: 0px 7px;
    border: 1px solid #e0e1e4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    font-family: var(--primary-font);

    &:focus-visible {
      outline: solid #ff4d4f2b;
    }

    &.file {
      all: unset;
      display: block;
    }
  }

  .preview-box {
    overflow: auto;
  }

  table {
    width: 700px;
    margin-bottom: 15px;


    // border: 1px solid #eaeaea;
    border: 1px solid #eaeaea;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 6px;
    overflow: hidden;

    th {
      color: var(--dark);
    }

    th,
    td {
      font-size: 12px;
      padding: 7px 5px;
      font-family: var(--primary-font);
      border-bottom: 1px solid #eaeaea;
    }

    td {
      color: var(--third-color);
      font-weight: 600;
    }
  }

  .enrichPreview {
    margin-top: 10px;
    overflow: auto;
  }

  .warning {
    background: hsl(48deg, 89%, 94%);
    color: #b7950b;
    padding: 10px;
    border-radius: 4px;
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 0px;
  }
}

.get_validation_credits {
  margin-top: 20px;
  background: #e0ddf8;
  border-radius: 5px;
  color: #4834d4;
  width: 60%;
  padding: 0px 0px;
  margin-left: 7px;
  display: inline-flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;

  .get_more_label {
    margin: auto;
  }

  .get_more_validation_credits {
    border-radius: 5px;
    display: inline-flex;
    border-left: 1px solid #3b24d7;
    width: 30%;
    margin-left: 10px;
    justify-content: center;
    padding: 5px 0px;
    background-color: #3b24d7c9;
    color: white;
    margin-left: auto;
  }
}

.unverified {
  color: #e67e22 !important;
  padding: 10px;
  margin: 10px;
  border-radius: 6px;
  background: #fae4d1;
  font-weight: 600;
  font-family: var(--primary-font);
  font-size: 0.8rem;
}

.not_enough_credits {
  margin: 10px;
  background: #e0ddf8;
  border-radius: 5px;
  color: #4834d4;
  padding: 10px;
}