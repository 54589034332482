.widget {
  padding: 20px 20px;
  cursor: pointer;
  background: white;
  font-weight: 600;
  position: relative;
  transition: 0.5s background;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 10px;
  border: 1px solid #ededed;

  .label {
    font-weight: 600;
    font-family: var(--primary-font);
    font-size: 12px;
  }

  .subtitle {
    font-weight: 500;
    font-family: var(--primary-font);
    font-size: 11px;
    color: #4953fa;
  }

  .total {
    font-weight: 800;
    font-family: var(--primary-font);
    color: var(--main-dark-color);
    font-size: 45px;
    line-height: initial;
  }

  .smallStats {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;

    & > div {
      display: grid;
      grid-template-columns: 20px 1fr;
      gap: 9px;
      align-items: center;
      font-weight: 700;
      font-family: var(--primary-font);
      color: #747d8c;
      font-size: 15px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin: 0px !important;
          width: 20px;
          object-fit: contain;
          height: 20px !important;
        }

        i {
          font-size: 18px !important;
          margin: 0px !important;
        }
      }
    }
  }
}
