.status {
  display: flex;
  gap: 15px;
  align-items: center;
  background: #f0f0f0;
  padding: 0px 20px;
  border-radius: 25px;
  margin-top: 4px;
  margin-left: 15px;

  div {
    cursor: pointer;
  }
}

.active{
  color: #e81821;
}