.box-stat {
  border: 1px solid hsl(0deg, 0%, 93%);
  // --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  //   0 4px 6px -2px rgba(0, 0, 0, 0.05);
  // box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
  //   var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 15px 20px;
  // border-radius: 8px;

  .count {
    font-size: 26px;
    font-weight: 600;
    font-family: var(--primary-font);
    line-height: normal;
    letter-spacing: -0.5px;
    color: black;
  }

  .name {
    font-family: var(--primary-font);
    font-weight: 400;
    color: #a4b0be;
  }
}
