.box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 20px;
  border-radius: 5px;
  transition: 0.5s opacity;
  opacity: 1;
}
