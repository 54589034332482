.mainProfile {
  background: #f9fafb;
  min-height: 100vh;
}

.superiorLink {
  color: hsl(0deg, 0%, 54%);
  font-family: var(--primary-font);
  font-weight: 500;
  margin-bottom: 10px;
  display: block;

  &:hover {
    color: black;
  }
}

.section {
  margin-top: 20px;

  .profileGrid {
    display: grid;
    grid-template-columns: 275px 1fr;
    background: white;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding: 0px 0px;
    border-radius: 5px;
    min-height: 500px;

    & > div:first-child {
      border-right: 1px solid #e3e3e3;
      padding-right: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    & > div:last-child {
      padding: 20px 30px;
    }
  }
}
