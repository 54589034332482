.home {
  .widgets {
    // background-color: red;
    margin-bottom: 40px;
    margin-top: 10px;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    grid-template-columns: 15fr 25fr 25fr 25fr;
    gap: 20px;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 750px) {
      grid-template-columns: 1fr;
    }
  }
  .container {
    padding: 3px 0px;
  }
  section {
    margin-top: 0px;

    h2 {
      font-family: var(--primary-font);
      font-size: 20px;
      font-weight: 700;
      color: var(--main-dark-color);
    }

    h3 {
      font-size: 0.8rem;
      line-height: initial;
      font-weight: 600;
      font-family: var(--primary-font);
      color: #747d8c;
    }

    .campaigns {
      margin-bottom: 30px;
      position: relative;

      h3 {
        font-family: var(--primary-font) !important;
        font-size: 16px !important;
        color: var(--main-dark-color) !important;
        font-weight: 700;
        line-height: normal;
        // margin-bottom: 15px;

        span {
          color: #000000b3;
          display: block;
          font-size: 13px !important;
          font-weight: 500;
          margin-top: 4px;
        }
      }

      &__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;

        &-addbox {
          background: #f3f3f3a3;
          width: 100%;
          color: var(--main-dark-color);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 115px;
          border-radius: 0px;
          border: 2px dashed #5c5c5c;
          cursor: pointer;
          transition: 0.5s background;
          gap: 15px;
          font-weight: 600;
          font-family: var(--primary-font);
          font-size: 15px;

          i {
            color: #d52d1b;
            font-size: 25px !important;
          }

          &:hover {
            background: #d52d1b;
            border-color: #d52d1b;
            color: white;

            i {
              color: white;
            }
          }
        }

        &-box {
          text-align: center;
          // background: linear-gradient(45deg, #d52d1a, #f09389);
          width: 100%;
          color: white;
          background: white;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 600;
          position: relative;
          transition: 0.5s background;
          --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

          .status {
            width: 70px;
            height: 115px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #dfe4ea;
            cursor: pointer;

            @media screen and (max-width: 800px) {
              height: 200px;
            }

            i {
              color: #747d8c;
            }

            &.RUNNING {
              background: #4b7bec;

              i {
                color: white;
              }
            }

            &.COMPLETED {
              background: #1dd1a1;

              i {
                color: white;
              }
            }
          }

          .details {
            height: 115px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media screen and (max-width: 800px) {
              height: auto;
              gap: 20px;
            }

            .name {
              color: var(--main-dark-color);
              font-family: var(--primary-font);
              text-align: left;
            }

            .campaign_sender {
              text-align: left;
              display: flex;
              gap: 25px;

              @media screen and (max-width: 800px) {
                flex-direction: column;
                gap: 0px;
              }
            }

            .stats {
              display: flex;
              gap: 10px;
              margin: 3px 0px;

              & > div {
                background: #4953fa;
                padding: 2px 10px;
                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: space-between;
                border-radius: 3px;
                font-family: var(--primary-font);
                font-size: 12px;

                i {
                  font-size: 12px !important;
                  color: white;
                }
              }
            }
          }

          .actions {
            display: flex;
            gap: 15px;

            p,
            a {
              color: #a4b0be;
              font-weight: 500;
              font-family: var(--primary-font);
              font-size: 13px;
              line-height: normal;
              margin: 0px !important;
              cursor: pointer;

              &:hover {
                color: #6a7e95;
              }
            }
          }

          i {
            color: #d52d1b;
            font-size: 25px !important;
          }
        }
      }

      .loading-container {
        text-align: center;
        width: 100%;
        // background: white;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
        position: relative;
        transition: 0.5s background;
        flex-direction: column;
        padding: 20px 0px;

        // border: 1px solid hsl(0deg, 0%, 94%);
        border-radius: 0px;

        .text {
          font-weight: 400;
          font-family: var(--primary-font);
          color: #6a7383;
        }
      }
    }
  }
}
