.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .premium {
    color: #3742fa;
    font-size: 11px;
    font-family: var(--primary-font);
    font-weight: 500;
    background: hsl(237deg, 95%, 95%);
    border-radius: 30px;
    padding: 1px 6px 2px;
  }

  textarea {
    width: 100%;
    border: 1px solid #e0e1e4;
    border-radius: 4px;
    font-family: var(--primary-font);
    font-size: 13px;
    font-weight: 500;
    padding: 5px 7px;
  }

  label {
    font-weight: 600;
    font-family: var(--primary-font);
    margin-bottom: 5px;
    font-size: 13px;
    color: var(--main-dark-color);

    span {
      color: #a3a3a3;
      font-size: 11px;
    }
  }

  input {
    height: 28px;
    padding: 0px 7px;
    border: 1px solid #e0e1e4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    font-family: var(--primary-font);

    &:focus-visible {
      outline: solid #ff4d4f2b;
    }
  }

  .buttonsFlex {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 10px;

    button {
      background: var(--third-color);
      color: white;
      font-family: var(--primary-font);
      font-weight: 600;
      border-radius: 4px;
      font-size: 12px;
      padding: 3px 7px;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
        #4953fa 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;

      &.testEmail {
        background: #57606f;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
          rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
          #57606f 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
          rgba(0, 0, 0, 0) 0px 0px 0px 0px,
          rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
      }
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.headerSection {
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 600;
  color: var(--main-dark-color);
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 7px;

  input {
    position: relative;
    top: 1px;
  }
}

.count {
  font-size: 11px;
  font-family: var(--primary-font);
}

select {
  height: 28px;
  padding: 0px 7px;
  border: 1px solid #e0e1e4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  font-family: var(--primary-font);
}

.captured {
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: var(--primary-font);
  font-size: 13px;
}
