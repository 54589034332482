.buttons-container {
  margin-top: 15px;
  display: flex;
  gap: 10px;
}

.container {
  @media screen and (max-width: 575px) {
    padding: 0px 15px !important;
  }
}

.button {
  background: #a4b0be;
  display: inline-block;
  color: white;
  padding: 5px 15px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px !important;
  height: 30px !important;
  transition: background 0.5s;

  &:disabled {
    background: #b9b9b9 !important;
  }

  &.primary {
    background: var(--primary-color);

    &:hover {
      background: #e43421;
    }
  }

  &.secondary {
    background: #57606f;
  }
}