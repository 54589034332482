.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .premium {
    color: #3742fa;
    font-size: 11px;
    font-family: var(--primary-font);
    font-weight: 500;
    background: hsl(237deg, 95%, 95%);
    border-radius: 30px;
    padding: 1px 6px 2px;
  }

  label {
    font-weight: 600;
    font-family: var(--primary-font);
    margin-bottom: 5px;
    font-size: 13px;
    color: var(--main-dark-color);

    span {
      font-size: 10px;
      color: #a4b0be;
    }
  }

  input {
    height: 28px;
    padding: 0px 7px;
    border: 1px solid #e0e1e4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    font-family: var(--primary-font);

    &:focus-visible {
      outline: solid #ff4d4f2b;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.headerSection {
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 600;
  color: var(--main-dark-color);
  margin-bottom: 10px;
  margin-top: 10px;
}

.count {
  font-size: 11px;
  font-family: var(--primary-font);
}

select {
  height: 28px;
  padding: 0px 7px;
  border: 1px solid #e0e1e4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  font-family: var(--primary-font);
}

.captured {
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: var(--primary-font);
  font-size: 13px;

  label {
    margin: 0px;
    font-weight: 400;
  }
}

.notes {
  font-family: var(--primary-font);
  font-size: 11px;
  font-style: italic;
}
