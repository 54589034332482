.qtyLeads {
  background: #f0f0f0;
  padding: 2px 10px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
  height: 25px;
  margin-right: 5px;
  cursor: pointer;
  z-index: 2;

  &.AILeads {
    padding-left: 30px;
    position: relative;
    top: 0px;

    img {
      margin: 0px;
      height: 14px;
      position: absolute;
      left: 9px;
    }
  }

  &.active {
    background: #222f3e;

    span,
    i {
      color: white !important;
    }
  }

  .qtyNumber {
    color: #7b7979;
    font-weight: 600;
    font-size: 12px;
  }
}

.download {
  background: none;
  height: 28px;
  border-radius: 5px;
  padding: 0px 13px;
  transition: 0.75s background;
  // border: 1px solid #d6d7db;
  font-family: var(--primary-font);
  font-weight: 500;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  color: #575757;

  i {
    font-size: 12px;
  }

  // &:hover {
  //   background: #000766;
  // }
}

.buttonsContainer {
  display: flex;
  gap: 10px;
}

.upgradeAll {
  font-size: 0.8rem;
  background: #fbdadc;
  z-index: 1;
  height: 24px;
  left: -30px;
  border-radius: 15px;
  color: #e81821 !important;
  padding: 0px 15px;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  font-weight: 500;
}

.verifyAll{
  font-size: 0.8rem;
  background: #ffedd1;
  z-index: 1;
  height: 24px;
  left: -30px;
  border-radius: 15px;
  color: #ff9800 !important;
  padding: 0px 15px;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  font-weight: 600;
}

.tooltip{
  width: 400px;
  padding: 20px 18px !important;
  font-family: var(--primary-font);
  font-weight: 500;
}